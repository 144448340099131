<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Banners</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Banners</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

    	<div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Add Banner</h3>

                    <form v-on:submit.prevent="addBanner" enctype="multipart/form-data">
                    	<div class="row">
                    		<div class="offset-md-1 col-md-4">
                    			<div class="form-group">
                    				<label>Select Country</label>
                    				<select name="country" class="form-control" required>
                    					<option value="default">Default</option>
                    					<option v-for="c in $countryList" v-bind:value="c" v-text="c"></option>
                    				</select>
                    			</div>
                    		</div>

                    		<div class="col-md-4">
                    			<div class="form-group">
                    				<label>Upload Media(s)</label>
                    				<input type="file" name="files[]" multiple class="form-control" required />
                    			</div>
                    		</div>

                    		<div class="col-md-1">
                    			<input type="submit" name="submit" class="btn btn-primary"
                    				v-bind:value="isAdding ? 'Uploading...' : 'Upload'"
                    				v-bind:disabled="isAdding"
                    				style="position: relative;
                    					top: 50%;
                    					transform: translateY(-50%);" />
                    		</div>
                    	</div>
                    </form>
				</div>
			</div>
		</div>

    	<div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Banners (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table table-bordered" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>Country</th>
                                	<th>Images</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data" v-bind:key="'banner-' + d.id">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">Country</th>
													<td v-text="d.country"></td>
												</tr>

												<tr>
													<th class="border-top-0">Images</th>
													<td>
														<template v-for="file in d.files">
				                            				<img v-bind:src="file" style="width: 100px;" />
				                            			</template>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
	                            		<td>
	                            			<span v-text="d.country"></span>
	                            		</td>

	                            		<td>
	                            			<template v-for="file in d.files">
	                            				<img v-bind:src="file" style="width: 100px;" />
	                            			</template>
	                            		</td>

	                            		<td>
	                            			<form v-on:submit.prevent="deleteImages">
	                            				<input type="hidden" name="id" v-model="d.id" required />
	                            				<input type="submit" name="submit" class="btn btn-danger" value="Delete" style="color: white;" />
	                            			</form>
	                            		</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../vuex/store"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "Banners",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				searchTimer: null,
				isAdding: false
			}
		},

		methods: {

			deleteImages() {
				const self = this
				const form = event.target

				swal.fire({
					title: 'Delete country?',
					text: "Are you sure you want to delete this country images ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData(form)

						const originalHtml = form.submit.innerHTML
						form.submit.setAttribute("disabled", "disabled")

		        		try {
						    const response = await axios.post(
						        self.$apiURL + "/admin/delete_banner",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )

						    if (response.data.status == "success") {
						    	// swal.fire("Success", response.data.message, "success")

						    	for (let a = self.data.length - 1; a >= 0; a--) {
						    		if (self.data[a].id == form.id.value) {
						    			self.data.splice(a, 1)
						    			break
						    		}
						    	}
						    } else {
						    	swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.data?.message) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							form.submit.innerHTML = originalHtml
							form.submit.removeAttribute("disabled")
						}
					}
				})
			},

			async addBanner() {
				const form = event.target
				const formData = new FormData(form)

				this.isAdding = true

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/add_banner",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    if (response.data.status == "success") {
				    	// swal.fire("Success", response.data.message, "success")

				    	let flag = false
				    	for (let a = 0; a < this.data.length; a++) {
				    		if (this.data[a].country == form.country.value) {
				    			this.data[a] = response.data.banner
				    			flag = true
				    			break
				    		}
				    	}

				    	if (!flag) {
					    	this.data.unshift(response.data.banner)
					    }
				    } else {
				    	swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			},

			onKeyPressSearch() {
				const self = this
				clearTimeout(this.searchTimer)

				this.searchTimer = setTimeout(function () {
					self.pageNumber = 1
					self.addOrUpdateURLParam("page", 1)

					self.getData()
				}, 1000)
			},

			paginate (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

			async getData () {
        		const self = this
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/fetch_banners",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    this.data = response.data.data
				    this.pages = response.data.pages
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#data-table").DataTable({
					// 	ordering: false
					// })
				}
	        }
		},

		mounted() {
        	this.getData()
        	document.title = "Banners"
        }
	}
</script>
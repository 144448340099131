import { createStore } from "vuex"
 
export default createStore({
    state() {
        return {
            user: null,
            loading: false,
            messages: [],
            selectedUserEmail: "",
            selectedUserName: "",
            socketIO: null,
            unreadContactUs: 0,
            unreadMessages: 0,
            bidUpdated: 0,
            auctionSheet: 0,
            images: 0,
            startingPrice: 0,
            emailContent: ""
        }
    },
 
    mutations: {
        setEmailContent(state, val) {
            state.emailContent = val
        },

        setStartingPrice (state, val) {
            if (val < 0) {
                val = 0
            }
            state.startingPrice = val
        },

        setImages (state, val) {
            if (val < 0) {
                val = 0
            }
            state.images = val
        },

        setAuctionSheet (state, val) {
            if (val < 0) {
                val = 0
            }
            state.auctionSheet = val
        },

        setBidUpdated (state, val) {
            if (val < 0) {
                val = 0
            }
            state.bidUpdated = val
        },

        setUnreadMessages (state, val) {
            if (val < 0) {
                val = 0
            }
            state.unreadMessages = val
        },

        setUnreadContactUs (state, val) {
            if (val < 0) {
                val = 0
            }
            state.unreadContactUs = val
        },

        setSocketIO (state, val) {
            state.socketIO = val
        },

        setSelectedUserName (state, val) {
            state.selectedUserName = val
        },

        setSelectedUserEmail (state, val) {
            state.selectedUserEmail = val
        },

        pushMessage (state, val) {
            state.messages.push(val)
        },

        setMessages (state, val) {
            state.messages = val
        },

        setLoading (state, val) {
            state.loading = val
        },

        setUser (state, val) {
            state.user = val
        },
    },
 
    getters: {
        getEmailContent (state) {
            return state.emailContent
        },

        getStartingPrice (state) {
            if (state.startingPrice < 0) {
                state.startingPrice = 0
            }
            return state.startingPrice
        },

        getImages (state) {
            if (state.images < 0) {
                state.images = 0
            }
            return state.images
        },

        getAuctionSheet (state) {
            if (state.auctionSheet < 0) {
                state.auctionSheet = 0
            }
            return state.auctionSheet
        },

        getBidUpdated (state) {
            if (state.bidUpdated < 0) {
                state.bidUpdated = 0
            }
            return state.bidUpdated
        },

        getUnreadMessages (state) {
            if (state.unreadMessages < 0) {
                state.unreadMessages = 0
            }
            return state.unreadMessages
        },

        getUnreadContactUs (state) {
            if (state.unreadContactUs < 0) {
                state.unreadContactUs = 0
            }
            return state.unreadContactUs
        },

        getSocketIO (state) {
            return state.socketIO
        },

        getSelectedUserName (state) {
            return state.selectedUserName
        },

        getSelectedUserEmail (state) {
            return state.selectedUserEmail
        },

        getMessages (state) {
            return state.messages
        },

        getLoading (state) {
            return state.loading
        },

        getUser (state) {
            return state.user
        }
    }
})
<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Investor Panel</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Investor Panel</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>
    <div class="container-fluid">
        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" name="search" v-on:keypress="onKeyPressSearch" v-model="search" class="form-control p-0 border-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Cars for Sale (<span v-text="data.length"></span>)</h3>
                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>
                    <div class="table-responsive">
                        <table v-if="!loading" class="table" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>
                                <tr v-else>
                                	<th>User</th>
                                	<th>LOT</th>
                                	<th>IMAGES</th>
                                	<th>DATE</th>
                                	<th>year</th>
                                	<th>NAME</th>
                                	<th>Chassis</th>
                                	<th>Package</th>
                                	<th>mileage</th>
                                	<th>Displace</th>
                                	<th>Transmission</th>
                                	<th>Package</th>
                                	<th>color</th>
                                	<th>Price</th>
                                	<th>Status</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
	                            					<th class="border-top-0">User</th>
	                            					<td>
														<router-link v-text="d.userName" v-bind:to="'/users/edit/' + d.userId"></router-link>
													</td>
	                            				</tr>
	                            				<tr>
													<th class="border-top-0">LOT</th>
													<td v-text="d.LOT"></td>
												</tr>
												<tr>
													<th class="border-top-0">Images</th>
													<td>
														<a v-bind:href="$homeURL + '/carDetail/' + d.carId" target="_blank" style="display: grid;">
															<template v-for="(img, index) in 3">
																<img v-if="index < d.images.length" v-bind:src="getImage(d.images, index)" style="width: 100px;" />
															</template>
														</a>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Date</th>
													<td v-text="d.date"></td>
												</tr>

												<tr>
													<th class="border-top-0">year</th>
													<td v-text="d.year"></td>
												</tr>

												<tr>
													<th class="border-top-0">Name</th>
													<td v-text="d.make + ' ' + d.model"></td>
												</tr>

												<tr>
													<th class="border-top-0">Chassis</th>
													<td v-text="d.chassis"></td>
												</tr>

												<tr>
													<th class="border-top-0">Package</th>
													<td v-html="d.package"></td>
												</tr>

												<tr>
													<th class="border-top-0">mileage</th>
													<td v-text="d.mileage + ' km'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Displace</th>
													<td v-text="d.engine + ' cc'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Transmission</th>
													<td v-text="d.transmission"></td>
												</tr>
												
												<tr>
													<th class="border-top-0">package</th>
													<td v-text="d.grade"></td>
												</tr>

												<tr>
													<th class="border-top-0">color</th>
													<td v-text="d.color"></td>
												</tr>
												
												<tr>
													<th class="border-top-0">Starting Price</th>
													<td v-html="'&yen;' + d.price"></td>
												</tr>

												<tr>
													<th class="border-top-0">User</th>
													<td>
														<router-link v-text="d.userName + ' | ' + d.userEmail" v-bind:to="'/users/edit/' + d.userId"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<form onsubmit="return false">
															<input type="hidden" name="id" v-model="d.carForSaleId" required />
															<select class="form-control" name="status" required v-on:change="changeStatus">
																<option value="pending" v-bind:selected="d.carForSaleStatus == 'pending'">Pending</option>
																<option value="active" v-bind:selected="d.carForSaleStatus == 'active'">Activate</option>
																<option value="sold" v-bind:selected="d.carForSaleStatus == 'sold'">Sold</option>
															</select>
														</form>

														<br />
														<!-- <router-link v-bind:to="'/carOrders/' + d.id">Orders</router-link> -->
														<!-- <router-link v-bind:to="'/userChat/' + d.userId">Chat</router-link> -->
														<router-link v-bind:to="'/carChat/' + d.carId">Chat</router-link>
													</td>
												</tr>
											</table>
										</th>
									</tr>
									<tr v-else>
										<td>
											<router-link v-text="d.userName" v-bind:to="'/users/edit/' + d.userId"></router-link>
										</td>
	                            		<td v-text="d.LOT"></td>
	                            		<td>
											<a v-bind:href="$homeURL + '/carDetail/' + d.carId"
												target="_blank">
												<template v-for="(img, index) in 3">
													<img v-if="index < d.images.length" v-bind:src="getImage(d.images, index)" style="width: 200px;" />
												</template>
											</a>
										</td>
										<td v-text="d.date"></td>
										<td v-text="d.year"></td>
										<td v-text="d.make + ' ' + d.model"></td>
										<td v-text="d.chassis"></td>
										<td v-html="d.package"></td>
										<td v-text="d.mileage + ' km'"></td>
										<td v-text="d.engine + ' cc'"></td>
										<td v-text="d.transmission"></td>
										<td v-text="d.grade"></td>
										<td v-text="d.color"></td>
										<td v-html="'&yen;' + d.price"></td>
										<td v-text="d.carsForSaleStatus"></td>
										<td>
											<form onsubmit="return false">
												<input type="hidden" name="id" v-model="d.carForSaleId" required />
												<select class="form-control" name="status" required v-on:change="changeStatus">
													<option value="pending" v-bind:selected="d.carsForSaleStatus == 'pending'">Pending</option>
													<option value="active" v-bind:selected="d.carsForSaleStatus == 'active'">Activate</option>
													<option value="sold" v-bind:selected="d.carsForSaleStatus == 'sold'">Sold</option>
												</select>
											</form>

											<br />
											<!-- <router-link v-bind:to="'/carOrders/' + d.id">Orders</router-link> -->
											<!-- <router-link v-bind:to="'/userChat/' + d.userId">Chat</router-link> -->
											<router-link v-bind:to="'/carChat/' + d.carId">Chat</router-link>
										</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"
    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"
	export default {
		name: "InvestorPanelComponent",
		data() {
			return {
				loading: false,
				data: [],
				search: "",
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},
		methods: {
			changeStatus() {
				const self = this
				const select = event.target
				const form = select.parentElement
				const status = form.status.value
				let title = ""
				let confirmButtonText = ""
				if (status == "pending") {
					title = "This car will stop being displaying in stock ?"
					confirmButtonText = "Pending"
				} else if (status == "active") {
					title = "This car will start displaying in stock ?"
					confirmButtonText = "Activate"
				} else if (status == "sold") {
					title = "Are you sure you want to mark this car as sold ?"
					confirmButtonText = "Sold"
				}
				swal.fire({
					title: title,
					showCancelButton: true,
					confirmButtonText: confirmButtonText,
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData(form)
		        		try {
		        			// form.submit.setAttribute("disabled", "disabled")
		        			const response = await axios.post(
						        self.$apiURL + "/admin/investor/changeCarStatus",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						    // swal.fire("Cars for Sale", response.data.message, response.data.status)
						    if (response.data.status == "success") {
						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].carForSaleId == form.id.value) {
						    			self.data[a].carForSaleStatus = status
						    		}
						    	}
						    }
		        		} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							// form.submit.removeAttribute("disabled")
						}
					}
				})
			},
			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			getData: async function () {
        		const self = this
        		this.pages = 0
        		this.loading = true
        		this.data = []
        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }
        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)
        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/investor/fetchCarsForSale",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				    this.loading = false
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
						this.pageNumber = response.data.pageNumber
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        }
		},
		mounted() {
			this.getData()
			document.title = "Investor Panel"
		}
	}
</script>
<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Steering Wheels</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Steering Wheels</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                    	<div class="row">
                    		<form style="display: contents;" v-on:submit.prevent="search">
	                    		<div class="col-md-3">
	                    			<div class="form-group">
	                    				<label>LOT</label>
	                    				<input type="text" name="lot" v-model="lot" class="form-control" />
	                    			</div>
	                    		</div>

	                    		<div class="col-md-3">
	                    			<div class="form-group" style="margin-left: 10px;">
	                    				<label>Auction Date</label>
	                    				<input type="text" name="auctionDate" v-model="auctionDate" autocomplete="off" class="form-control date" />
	                    			</div>
	                    		</div>

	                    		<div class="col-md-3">
	                    			<div class="form-group" style="margin-left: 10px;">
	                    				<label>Auction House</label>
	                    				<input type="text" name="auctionHouse" v-model="auctionHouse" class="form-control" />
	                    			</div>
	                    		</div>

	                    		<div class="col-md-2">
	                    			<div class="form-group" style="margin-left: 10px;">
	                    				<label>Steering</label>
	                    				<select name="steering" v-model="steering" class="form-control">
	                    					<option value="all">All</option>
	                    					<option value="left">Left</option>
	                    					<option value="right">Right</option>
	                    				</select>
	                    			</div>
	                    		</div>

	                    		<div class="col-md-1">
	                    			<input type="submit" name="submit" class="btn btn-info" value="Search"
	                    				style="margin-left: 10px; color: white;
	                    					position: relative; top: 37%;" />
	                    		</div>
	                    	</form>
                    	</div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Cars Listing</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                    	<table class="table" id="data-table">
                    		<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>Car</th>
                                	<th>Auction Date</th>
                                	<th>Auction House</th>
                                	<th>Lot</th>
                                	<th>Steer</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(car, index) in cars">
                            		<tr v-if="$isMobile()">
                            			<th>
                            				<table>
                            					<tr>
                            						<th>Car</th>
			                            			<td>
				                            			<a v-bind:href="$homeURL + '/car-details.php?id=' + car.ID">
				                            				<span v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME
				                            				+ ' - ' + car.YEAR"></span>
				                            			</a>
				                            		</td>
                            					</tr>

                            					<tr>
			                            			<th>Auction Date</th>
			                            			<td v-text="car.AUCTION_DATE.split(' ')[0]"></td>
			                            		</tr>

			                            		<tr>
			                            			<th>Auction House</th>
			                            			<td v-text="car.AUCTION"></td>
			                            		</tr>

			                            		<tr>
			                            			<th>LOT</th>
			                            			<td v-text="car.LOT"></td>
			                            		</tr>

			                            		<tr>
			                            			<th>Steer</th>
			                            			<td v-text="car.steer ?? ''"></td>
			                            		</tr>

			                            		<tr>
			                            			<th>Actions</th>
			                            			<td>
				                            			<label>
				                            				<input type="radio" v-on:click="steeringWheelSelected(car.ID, 'left')" v-bind:checked="car.steer == 'left'" />
				                            				Left
				                            			</label>

				                            			<label style="margin-left: 10px;">
				                            				<input type="radio" v-on:click="steeringWheelSelected(car.ID, 'right')" v-bind:checked="car.steer == 'right'" />
				                            				Right
				                            			</label>
				                            		</td>
			                            		</tr>
                            				</table>
                            			</th>
                            		</tr>

                            		<tr v-else v-bind:id="'car-id-' + car.ID">
	                            		<td>
	                            			<a href="javascript:void(0)" v-on:click="show_car_images(car)">
	                            				<span v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME
	                            				+ ' - ' + car.YEAR"></span>
	                            			</a>
	                            		</td>

	                            		<td v-text="car.AUCTION_DATE.split(' ')[0]"></td>
	                            		<td v-text="car.AUCTION"></td>
	                            		<td v-text="car.LOT"></td>

	                            		<td v-text="car.steer ?? ''"></td>

	                            		<td>
	                            			<label>
	                            				<input type="radio" v-on:click="steeringWheelSelected(car.ID, 'left')" v-bind:checked="car.steer == 'left'" />
	                            				Left
	                            			</label>

	                            			<label style="margin-left: 10px;">
	                            				<input type="radio" v-on:click="steeringWheelSelected(car.ID, 'right')" v-bind:checked="car.steer == 'right'" />
	                            				Right
	                            			</label>
	                            		</td>
	                            	</tr>
	                            	
	                            </template>
	                        </tbody>
	                    </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination" style="overflow-x: scroll;">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
	<div class="modal fade" id="car-images-modal" v-if="selected_car != null">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" v-text="selected_car.MARKA_NAME + ' ' + selected_car.MODEL_NAME + ' - ' + selected_car.YEAR"></h5>
				</div>

				<div class="modal-body">
					<img v-for="(image, index) in selected_car.IMAGES" v-bind:src="image"
						style="width: 100%;" />
				</div>

				<div class="modal-footer">
					<button type="button" class="btn btn-info" style="color: white;" v-on:click="close_images_modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

    import "../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../assets/datetimepicker/jquery.datetimepicker.full.js"

	export default {
		name: "SteeringWheelComponent",

		data() {
			return {
				loading: false,
				pages: 0,
				cars: [],
				dataTable: null,
				pageNumber: this.$route.query.page || 1,
				lot: "",
				auctionDate: "",
				auctionHouse: "",
				selected_car: null,
				steering: "all"
			}
		},

		methods: {
			close_images_modal() {
				$("#car-images-modal").modal("hide")
				this.selected_car = null
			},

			show_car_images(car) {
				this.selected_car = car

				setTimeout(function () {
					$("#car-images-modal").modal("show")
				}, 0)
			},

			async steeringWheelSelected(ID, value) {
				// const value = event.target.value

        		const formData = new FormData()
        		formData.append("ID", ID)
        		formData.append("steer", value)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/steering/set",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    if (response.data.status == "success") {
				    	// console.log([this.cars, ID])
				    	
				    	let index = -1
				    	for (let a = 0; a < this.cars.length; a++) {
				    		console.log([this.cars[a].ID, ID])

				    		if (this.cars[a].ID == ID) {
				    			this.cars[a].steer = value
				    			this.cars.splice(a, 1)
				    			index = a
				    			break
				    		}
				    	}

				    	if (index > -1) {
				    		document.getElementById("car-id-" + this.cars[index].ID).remove()
				    	}
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					console.log(error)

					if (error?.response?.data?.message) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				}
			},

			search() {
				this.cars = []
				this.pages = 0
				this.pageNumber = 1
				this.addOrUpdateURLParam("page", this.pageNumber)
				this.getData()
			},

			paginate(page) {
				this.cars = []
				this.pages = 0
				this.pageNumber = page
				this.addOrUpdateURLParam("page", this.pageNumber)
				this.getData()
			},

			async getData () {
        		const self = this
        		this.loading = true

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("page", this.pageNumber)
        		formData.append("lot", this.lot)
        		formData.append("auctionDate", this.auctionDate)
        		formData.append("auctionHouse", this.auctionHouse)
        		formData.append("steering", this.steering)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/steering/fetchCars",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.cars = response.data.cars
				    	this.pages = response.data.pages
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.data?.message) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#data-table").DataTable({
					// 	ordering: false
					// })
				}
	        },

	        getDate(date, dateObj) {
	        	if (!date) {
					let month = (dateObj.getMonth() + 1)
					if (month < 10) {
						month = "0" + month
					}

					let dateVal = dateObj.getDate()
					if (dateVal < 10) {
						dateVal = "0" + dateVal
					}

					date = dateObj.getFullYear() + "-" + month + "-" + dateVal
				}

				this.auctionDate = date
	        }
		},

		mounted() {
			const self = this
			this.getDate(this.$route.query.date, new Date())
        	this.getData()
        	document.title = "Steering Wheels"

        	jQuery(".date").datetimepicker({
                "format": "Y-m-d",
                "timepicker": false,
                "onSelectDate": function() {
                	const d = jQuery('.date').datetimepicker('getValue')
                	self.getDate(null, new Date(d))
                	self.addOrUpdateURLParam("date", self.auctionDate)
                    self.getData()
                },
                "scrollMonth": false
            })
        }
	}
</script>

<style scoped>
	.table td, .table th {
		padding: 5px 0px !important;
	}
</style>
<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">User Chats</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">User Chats</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title text-center" v-if="user != null" v-text="'(' + user.name + ' | ' + user.email + ')'"></h3>
                    
                    <div class="container">
                        <h3 class="text-center">Messaging</h3>
                        <div class="messaging">
                            <div class="inbox_msg">
                                <div class="inbox_people">
                                    <div class="headind_srch">
                                        <div class="recent_heading">
                                            <h4>Recent</h4>
                                        </div>
                                        <div class="srch_bar">
                                            <div class="stylish-input-group">
                                                <input type="text" class="search-bar" placeholder="Search" v-model="search" v-on:keyup="onkeyupSearch" />
                                                <span class="input-group-addon">
                                                    <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inbox_chat">
                                        <template v-for="(c, index) in contacts">
                                            <div v-bind:class="'chat_list' + (c.userId == userId ? ' active_chat' : '')" v-on:click="fetchUserChat(c.userId)" style="cursor: pointer;">
                                                <div class="chat_people">
                                                    <div class="chat_img">
                                                        <img src="../../assets/images/user-profile.png" />
                                                    </div>
                                                    
                                                    <div class="chat_ib">
                                                        <h5>
                                                        	<span v-html="c.name + '<br />' + c.email" style="float: none !important; font-size: initial;"></span>
                                                            <span class="chat_date" v-text="c.updated_at"></span>
                                                        </h5>
                                                        <p v-text="c.lastMessage"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="mesgs">

                                    <div class="text-center" v-if="loading" style="margin-bottom: 50px;">
                                        <div class="spinner-border">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                    <button type="button" v-if="hasMoreMessages" class="btn btn-light btn-load-more-chat" style="margin-bottom: 50px;
                                        position: relative;
                                        left: 50%;" v-on:click="loadMoreChat">
                                        <i v-bind:class="'ti-reload ' + loadMoreAnimationClass"></i>
                                    </button>

                                    <div class="msg_history" id="messages-chat">

                                        <template v-for="(message, index) in messages">

                                            <template v-if="message.type == 'message'">
                                                <template v-if="message.receiverId == userId">
                                                    <div class="incoming_msg">
                                                        <div class="incoming_msg_img">
                                                            <img src="../../assets/images/user-profile.png" />
                                                        </div>

                                                        <div class="received_msg">
                                                            <div class="received_withd_msg">
                                                                <p v-html="message.message"></p>
                                                                <span class="time_date" v-text="message.sentAt"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-else>
                                                    <div class="outgoing_msg">
                                                        <div class="sent_msg">
                                                            <p v-html="message.message"></p>
                                                            <span class="time_date" v-text="message.sentAt"></span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>

                                            <template v-else-if="message.type == 'order'">
                                            	<div class="order">
		                                            <h2>Order</h2>
		                                            
		                                            <h5>
		                                            	<a v-bind:href="$homeURL + '/car-details.php?id=' + message.carId" v-text="message.car" class="order-car-name" target="_blank"></a>
		                                            </h5>

		                                            <p v-html="'Amount: &yen;' + message.orderAmount"></p>
		                                            
		                                            <p v-text="'Status: ' + message.orderStatus.toUpperCase()"></p>
		                                        </div>
                                            </template>
                                        </template>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "UserChatComponent",

		data() {
			return {
				id: this.$route.params.id,
				contacts: [],
				user: null,
				contact: null,
				messages: [],
				userId: 0,
				hasMoreMessages: false,
				loading: false
			}
		},

		methods: {
			async decryptMessage(message, iv, privateKey, publicKey) {
                try {
                    iv = new Uint8Array(atob(iv).split("").map(function(c) {
                        return c.charCodeAt(0)
                    }))
                    const initializationVector = new Uint8Array(iv).buffer
                    const string = atob(message)
                    const uintArray = new Uint8Array(
                        [...string].map((char) => char.charCodeAt(0))
                    )
                    const derivedKey = await this.getDerivedKey(privateKey, publicKey)
                    const decryptedData = await window.crypto.subtle.decrypt(
                        {
                            name: "AES-GCM",
                            iv: initializationVector,
                        },
                        derivedKey,
                        uintArray
                    )
                    const decryptedString = new TextDecoder().decode(decryptedData)
                    return decryptedString
                } catch (e) {
                    return `error decrypting message: ${e}`
                    return ""
                }
            },

            async getDerivedKey(privateKey, publicKey) {
                try {
                    const publicKeyObj = await window.crypto.subtle.importKey(
                        "jwk",
                        publicKey,
                        {
                            name: "ECDH",
                            namedCurve: "P-256",
                        },
                        true,
                        []
                    )
                    const privateKeyObj = await window.crypto.subtle.importKey(
                        "jwk",
                        privateKey,
                        {
                            name: "ECDH",
                            namedCurve: "P-256",
                        },
                        true,
                        ["deriveKey", "deriveBits"]
                    )
                    const derivedKey = await window.crypto.subtle.deriveKey(
                        { name: "ECDH", public: publicKeyObj },
                        privateKeyObj,
                        { name: "AES-GCM", length: 256 },
                        true,
                        ["encrypt", "decrypt"]
                    )
                    return derivedKey
                } catch (e) {
                    return null
                }
            },

			async fetchUserChat(userId) {
				this.loading = true
				this.messages = []
				this.userId = userId
				const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

				const formData = new FormData()
	            formData.append("id", this.id)
	            formData.append("userId", userId)
	            formData.append("timeZone", timeZone)

	            try {
	                const response = await axios.post(
	                    this.$apiURL + "/admin/users/fetchChatWith",
	                    formData, {
	                        headers: this.$headers
	                    }
	                )

	                if (response.data.status == "success") {
	                	this.contact = response.data.contact

	                	const chats = response.data.chats
                        for (let a = 0; a < chats.length; a++) {
                            chats[a].message = await this.decryptMessage(chats[a].message, chats[a].iv, this.user.privateKey, this.contact.publicKey)
                        }
	                	this.messages = chats
	                	this.hasMoreMessages = response.data.hasMoreChat
	                } else {
	                    swal.fire("Error", response.data.message, "error")
	                }
	            } catch (error) {
	                if (error?.response?.data?.message) {
	                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
	                }
	            } finally {
	            	this.loading = false
	            }
			},

			async getData() {
				const formData = new FormData()
	            formData.append("id", this.id)

	            try {
	                const response = await axios.post(
	                    this.$apiURL + "/admin/users/fetchContacts",
	                    formData, {
	                        headers: this.$headers
	                    }
	                )

	                if (response.data.status == "success") {
	                    this.contacts = response.data.contacts
	                    this.user = response.data.user
	                } else {
	                    swal.fire("Error", response.data.message, "error")
	                }
	            } catch (error) {
	                if (error?.response?.data?.message) {
	                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
	                }
	            }
			}
		},

		mounted() {
	        this.getData()
	        document.title = "User Chat"
	    }
	}
</script>

<style scoped>

.order-car-name:hover {
	text-decoration: underline;
}
.order-car-name {
	color: white;
}

.order h2,
.order h5 {
    color: white;
    text-align: center;
}

.order p {
    margin-bottom: 0px;
    color: white;
    text-align: center;
}

.order {
    background-color: limegreen;
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 50px;
    border-radius: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.container{max-width:1170px; margin:auto;}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}

</style>
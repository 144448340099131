<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Live Video</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Live Video</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

    	<div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Add Live Video</h3>

                    <form v-on:submit.prevent="addLiveVideo" enctype="multipart/form-data">
                    	<div class="row">
                    		<div class="offset-md-1 col-md-4">
                    			<div class="form-group">
                    				<label>Enter Car ID</label>
                    				<input type="text" name="id" class="form-control" required />
                    			</div>
                    		</div>

                    		<div class="col-md-4">
                    			<div class="form-group">
                    				<label>Upload Video</label>
                    				<input type="file" name="video" accept="video/*" class="form-control" required />
                    			</div>
                    		</div>

                    		<div class="col-md-1">
                    			<input type="submit" name="submit" class="btn btn-primary"
                    				v-bind:value="isAdding ? 'Uploading...' : 'Upload'"
                    				v-bind:disabled="isAdding"
                    				style="position: relative;
                    					top: 50%;
                    					transform: translateY(-50%);" />
                    		</div>
                    	</div>
                    </form>
				</div>
			</div>
		</div>

    	<div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Live Video (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table table-bordered" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>Car</th>
                                	<th>Video</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data" v-bind:key="'live-video-' + d.id">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">Name</th>
													<td v-text="d.name"></td>
												</tr>

												<tr>
													<th class="border-top-0">Email</th>
													<td v-text="d.email"></td>
												</tr>

												<tr>
													<th class="border-top-0">Subject</th>
													<td v-text="d.subject"></td>
												</tr>

												<tr>
													<th class="border-top-0">Message</th>
													<td v-text="d.message"></td>
												</tr>

												<tr>
													<th class="border-top-0">Email Content</th>
													<td>
	                            						<i class="fa fa-envelope" style="cursor: pointer;" v-on:click="viewContent(d.id)"></i>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
	                            		<td>
	                            			<a v-bind:href="$homeURL + '/car-details.php?id=' + d.CAR_ID"
	                            				v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"
	                            				target="_blank"></a>
	                            		</td>

	                            		<td>
	                            			<video v-bind:src="d.live_video" controls style="width: 300px;"></video>
	                            		</td>                          		
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../vuex/store"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "LiveVideo",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				searchTimer: null,
				isAdding: false
			}
		},

		methods: {

			async addLiveVideo() {
				const form = event.target
				const formData = new FormData(form)

				this.isAdding = true

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/add_live_video",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    if (response.data.status == "success") {
				    	swal.fire("Success", response.data.message, "success")

				    	let flag = false
				    	for (let a = 0; a < this.data.length; a++) {
				    		if (this.data[a].CAR_ID == form.id.value) {
				    			this.data[a] = response.data.auction_car
				    			flag = true
				    			break
				    		}
				    	}

				    	if (!flag) {
					    	this.data.unshift(response.data.auction_car)
					    }
				    } else {
				    	swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			},

			onKeyPressSearch() {
				const self = this
				clearTimeout(this.searchTimer)

				this.searchTimer = setTimeout(function () {
					self.pageNumber = 1
					self.addOrUpdateURLParam("page", 1)

					self.getData()
				}, 1000)
			},

			paginate (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

			async getData () {
        		const self = this
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/fetch_live_videos",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    this.data = response.data.data
				    this.pages = response.data.pages
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#data-table").DataTable({
					// 	ordering: false
					// })
				}
	        }
		},

		mounted() {
        	this.getData()
        	document.title = "Live Video"
        }
	}
</script>
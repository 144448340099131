<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Upload documents</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Upload Documents</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title text-center" v-if="auctionCar != null"
                    	v-text="auctionCar.MARKA_NAME + ' ' + auctionCar.MODEL_NAME + ' - ' + auctionCar.YEAR"></h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

				    <form method="POST" enctype="multipart/form-data" v-on:submit.prevent="uploadDocuments">
				    	<div class="form-group">
				    		<label class="form-label">Select document type</label>
				    		<select name="type" class="form-control" required>
				    			<option value="">Document type</option>
				    			<option value="bl">Bill of Lading</option>
				    			<option value="fc">Fitness Certificate</option>
				    			<option value="exportCertificate">Export Certificate</option>
				    			<option value="invoice">Invoice</option>
				    			<option value="pictures">Pictures</option>
				    		</select>
				    	</div>

				    	<div class="form-group">
				    		<label class="form-label">Select Documents</label>
				    		<input type="file" name="files[]" multiple class="form-control" required />
				    	</div>

				    	<input type="submit" name="submit" class="btn btn-primary" value="Upload Document" />
				    </form>

				</div>
			</div>
		</div>

		<template v-if="auctionCar != null">
			<div class="row">
	            <div class="col-sm-12">
	                <div class="white-box">
	                    <h3 class="box-title">Bill of Ladings</h3>

	                    <table class="table table-bordered">
	                    	<thead>
	                    		<tr>
	                    			<th>Document</th>
	                    			<th>Actions</th>
	                    		</tr>
	                    	</thead>

	                    	<tbody>
	                    		<tr v-for="doc in auctionCar.bl">
	                    			<td>
	                    				<a v-bind:href="doc.url" target="_blank" v-text="doc.name"></a>
	                    			</td>

	                    			<td>
	                    				<button type="button" class="btn btn-danger btn-sm"
	                    					style="color: white;">Delete</button>
	                    			</td>
	                    		</tr>
	                    	</tbody>
	                    </table>

	                </div>
	            </div>
	        </div>

	        <div class="row">
	            <div class="col-sm-12">
	                <div class="white-box">
	                    <h3 class="box-title">Fitness Certificate</h3>

	                    <table class="table table-bordered">
	                    	<thead>
	                    		<tr>
	                    			<th>Document</th>
	                    			<th>Actions</th>
	                    		</tr>
	                    	</thead>

	                    	<tbody>
	                    		<tr v-for="doc in auctionCar.fc">
	                    			<td>
	                    				<a v-bind:href="doc.url" target="_blank" v-text="doc.name"></a>
	                    			</td>

	                    			<td>
	                    				<button type="button" class="btn btn-danger btn-sm"
	                    					style="color: white;">Delete</button>
	                    			</td>
	                    		</tr>
	                    	</tbody>
	                    </table>

	                </div>
	            </div>
	        </div>

	        <div class="row">
	            <div class="col-sm-12">
	                <div class="white-box">
	                    <h3 class="box-title">Export Certificate</h3>

	                    <table class="table table-bordered">
	                    	<thead>
	                    		<tr>
	                    			<th>Document</th>
	                    			<th>Actions</th>
	                    		</tr>
	                    	</thead>

	                    	<tbody>
	                    		<tr v-for="doc in auctionCar.exportCertificate">
	                    			<td>
	                    				<a v-bind:href="doc.url" target="_blank" v-text="doc.name"></a>
	                    			</td>

	                    			<td>
	                    				<button type="button" class="btn btn-danger btn-sm"
	                    					style="color: white;">Delete</button>
	                    			</td>
	                    		</tr>
	                    	</tbody>
	                    </table>

	                </div>
	            </div>
	        </div>

	        <div class="row">
	            <div class="col-sm-12">
	                <div class="white-box">
	                    <h3 class="box-title">Invoice</h3>

	                    <table class="table table-bordered">
	                    	<thead>
	                    		<tr>
	                    			<th>Document</th>
	                    			<th>Actions</th>
	                    		</tr>
	                    	</thead>

	                    	<tbody>
	                    		<tr v-for="doc in auctionCar.invoice">
	                    			<td>
	                    				<a v-bind:href="doc.url" target="_blank" v-text="doc.name"></a>
	                    			</td>

	                    			<td>
	                    				<button type="button" class="btn btn-danger btn-sm"
	                    					style="color: white;">Delete</button>
	                    			</td>
	                    		</tr>
	                    	</tbody>
	                    </table>

	                </div>
	            </div>
	        </div>

	        <div class="row">
	            <div class="col-sm-12">
	                <div class="white-box">
	                    <h3 class="box-title">Pictures</h3>

	                    <table class="table table-bordered">
	                    	<thead>
	                    		<tr>
	                    			<th>Document</th>
	                    			<th>Actions</th>
	                    		</tr>
	                    	</thead>

	                    	<tbody>
	                    		<tr v-for="doc in auctionCar.pictures">
	                    			<td>
	                    				<a v-bind:href="doc.url" target="_blank" v-text="doc.name"></a>
	                    			</td>

	                    			<td>
	                    				<button type="button" class="btn btn-danger btn-sm"
	                    					style="color: white;">Delete</button>
	                    			</td>
	                    		</tr>
	                    	</tbody>
	                    </table>

	                </div>
	            </div>
	        </div>
		</template>
	</div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

	export default {
		name: "UploadDocumentsComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				loading: false,
				data: [],
				auctionCar: null
			}
		},

		methods: {
			async uploadDocuments() {
				this.loading = true

				const form = event.target
				const formData = new FormData(form)
        		formData.append("id", this.id)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/auctionCars/uploadDocuments",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	swal.fire("Upload Documents", response.data.message, "success")
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.data?.message) {
						swal.fire("Error", error.response.data.message, "error")
					}
				} finally {
					this.loading = false
					form.reset()
				}
			},

			async getData() {
				const self = this
        		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        		this.loading = true

        		const formData = new FormData()
        		formData.append("id", this.id)
        		formData.append("timeZone", timeZone)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/auctionCars/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.auctionCar = response.data.auctionCar
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.data?.message) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
        	this.getData()
        	document.title = "Upload documents"
        }
	}
</script>
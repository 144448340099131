import { createApp } from 'vue'
import App from './App.vue'

import { createRouter, createWebHistory } from 'vue-router'
import VueMobileDetection from "vue-mobile-detection"

import store from "./vuex/store"

const app = createApp(App)
app.use(VueMobileDetection)

import LoginComponent from "./components/LoginComponent.vue"
import DashboardComponent from "./components/DashboardComponent.vue"
import AwardedCarsComponent from "./components/AwardedCarsComponent.vue"

import BidsComponent from "./components/Bids/BidsComponent.vue"
import SteeringWheelComponent from "./components/SteeringWheelComponent.vue"

import UserChatComponent from "./components/Users/UserChatComponent.vue"
import UsersComponent from "./components/Users/UsersComponent.vue"
import EditUserComponent from "./components/Users/EditUserComponent.vue"
import UserLogsComponent from "./components/Users/UserLogsComponent.vue"

import RequestedImagesComponent from "./components/Requests/RequestedImagesComponent.vue"
import AuctionSheetsComponent from "./components/Requests/AuctionSheetsComponent.vue"
import AuctionSheetTranslateComponent from "./components/Requests/AuctionSheetTranslateComponent.vue"

import TransactionsComponent from "./components/Transactions/TransactionsComponent.vue"
import AddTransactionComponent from "./components/Transactions/AddTransactionComponent.vue"
import EditTransactionComponent from "./components/Transactions/EditTransactionComponent.vue"
import TransactionLogsComponent from "./components/Transactions/TransactionLogsComponent.vue"

import TrackingComponent from "./components/Tracking/TrackingComponent.vue"
import UserTrackingComponent from "./components/UserTrackingComponent.vue"

import AuctionCarsComponent from "./components/AuctionCars/AuctionCarsComponent.vue"
// import UserCarsComponent from "./components/AuctionCars/UserCarsComponent.vue"
import EditAuctionCarComponent from "./components/AuctionCars/EditAuctionCarComponent.vue"

import MailsComponent from "./components/MailsComponent.vue"
import InvoicesComponent from "./components/Invoices/InvoicesComponent.vue"
import InvoiceEditComponent from "./components/Invoices/InvoiceEditComponent.vue"

import PartsComponent from "./components/Parts/PartsComponent.vue"
import EditPartComponent from "./components/Parts/EditPartComponent.vue"

import PaymentsComponent from "./components/PaymentsComponent.vue"
import OrdersComponent from "./components/Orders/OrdersComponent.vue"
import PagesComponent from "./components/Pages/PagesComponent.vue"
import EditPageComponent from "./components/Pages/EditPageComponent.vue"

import InvestorPanelComponent from "./components/InvestorPanel/InvestorPanelComponent.vue"
// import CarOrdersComponent from "./components/InvestorPanel/CarOrdersComponent.vue"
// import ViewChatComponent from "./components/InvestorPanel/ViewChatComponent.vue"
import PayeesComponent from "./components/Payees/PayeesComponent.vue"
import EditPayeeComponent from "./components/Payees/EditPayeeComponent.vue"
import SMSComponent from "./components/SMSComponent.vue"
import TestimonialsComponent from "./components/Testimonials/TestimonialsComponent.vue"
import EditTestimonialComponent from "./components/Testimonials/EditTestimonialComponent.vue"
import FAQsComponent from "./components/FAQs/FAQsComponent.vue"
import EditFAQComponent from "./components/FAQs/EditFAQComponent.vue"
import ConfigurationsComponent from "./components/Configurations/ConfigurationsComponent.vue"
import EditConfigurationComponent from "./components/Configurations/EditConfigurationComponent.vue"
import CountriesComponent from "./components/Countries/CountriesComponent.vue"
import EditCountryComponent from "./components/Countries/EditCountryComponent.vue"
import MessagesComponent from "./components/MessagesComponent.vue"
import UploadDocumentsComponent from "./components/UploadDocumentsComponent.vue"
import PanoramicComponent from "./components/PanoramicComponent.vue"
import ContactUs from "./components/ContactUs.vue"
import LiveVideo from "./components/LiveVideo.vue"
import Banners from "./components/Banners.vue"
import CarChatComponent from "./components/CarChatComponent.vue"
import IRSComponent from "./components/IRSComponent.vue"
import ReadContactUsMail from "./components/ReadContactUsMail.vue"

const routes = [
    { path: "/read-contact-us-mail/:id", component: ReadContactUsMail },
    { path: "/irs", component: IRSComponent },
    { path: "/banners", component: Banners },
    { path: "/liveVideo", component: LiveVideo },
    { path: "/contact-us", component: ContactUs },
    { path: "/panoramic", component: PanoramicComponent },
    { path: "/steering", component: SteeringWheelComponent },
    { path: "/chats", component: MessagesComponent },

    { path: "/countries/edit/:id", component: EditCountryComponent },
    { path: "/countries", component: CountriesComponent },

    { path: "/configurations/edit/:id", component: EditConfigurationComponent },
    { path: "/configurations", component: ConfigurationsComponent },

    { path: "/faqs/edit/:id", component: EditFAQComponent },
    { path: "/faqs", component: FAQsComponent },

    { path: "/testimonials/edit/:id", component: EditTestimonialComponent },
    { path: "/testimonials", component: TestimonialsComponent },
    { path: "/sms", component: SMSComponent },

    { path: "/payees/edit/:id", component: EditPayeeComponent },
    { path: "/payees", component: PayeesComponent},

    // { path: "/chat/:user1/:user2", component: ViewChatComponent },
    { path: "/carChat/:id", component: CarChatComponent },
    { path: "/userChat/:id", component: UserChatComponent },
    // { path: "/carOrders/:id", component: CarOrdersComponent },
    { path: "/investorPanel", component: InvestorPanelComponent },

    { path: "/pages/edit/:id", component: EditPageComponent },
    { path: "/pages", component: PagesComponent },
    { path: "/payments", component: PaymentsComponent },
    { path: "/orders", component: OrdersComponent },

    { path: "/parts/edit/:id", component: EditPartComponent },
    { path: "/parts", component: PartsComponent },

    { path: "/invoices/edit/:id", component: InvoiceEditComponent },
    { path: "/invoices", component: InvoicesComponent },

    // { path: "/userCars", component: UserCarsComponent },
    { path: "/auctionCars/edit/:carId", component: EditAuctionCarComponent },
    { path: "/auctionCars", component: AuctionCarsComponent },
    { path: "/userTracking", component: UserTrackingComponent },

    { path: "/transactions/logs/:id", component: TransactionLogsComponent },
    { path: "/transactions/edit/:id", component: EditTransactionComponent },
    { path: "/transactions/add", component: AddTransactionComponent },
    { path: "/transactions", component: TransactionsComponent },

    { path: "/requested/auctionSheets/translate/:id", component: AuctionSheetTranslateComponent },
    { path: "/requested/auctionSheets", component: AuctionSheetsComponent },
    { path: "/requested/images", component: RequestedImagesComponent },
    
    { path: "/awardedCars/documents/:id", component: UploadDocumentsComponent },
    { path: "/awardedCars/tracking/:id", component: TrackingComponent },
    { path: "/awardedCars", component: AwardedCarsComponent },

    { path: "/mails", component: MailsComponent },
    { path: "/bids", component: BidsComponent },
    
    { path: "/users/logs/:id", component: UserLogsComponent },
    { path: "/users/edit/:id", component: EditUserComponent },
    { path: "/users", component: UsersComponent },
    
    { path: "/", component: DashboardComponent },
    { path: "/login", component: LoginComponent }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

app.use(router)

// app.config.globalProperties.$apiBaseUrl = "http://localhost/ussglobal/api/public"
// app.config.globalProperties.$apiURL = "http://localhost/ussglobal/api/public/api"
// app.config.globalProperties.$mainURL = "http://localhost:8081"
// app.config.globalProperties.$homeURL = "http://localhost/ussglobal/web"
// app.config.globalProperties.$nodeURL = "http://localhost:3000"

app.config.globalProperties.$apiBaseUrl = "https://api.ussglobal.jp"
app.config.globalProperties.$apiURL = "https://api.ussglobal.jp/api"
app.config.globalProperties.$mainURL = "https://admin.ussglobal.jp"
app.config.globalProperties.$homeURL = "https://ussglobal.jp"
app.config.globalProperties.$nodeURL = "https://node.ussglobal.jp:8000"
app.config.globalProperties.$nodeURL = "http://node.ussglobal.jp:3000"

app.config.globalProperties.$accessToken = "UssGlobalAdminAccessToken"
app.config.globalProperties.$headers = {
    Authorization: "Bearer " + localStorage.getItem("UssGlobalAdminAccessToken")
}

app.config.globalProperties.$countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands"
]

app.mixin({

    computed: {
        user() {
            return store.getters.getUser
        }
    },

    methods: {

        closeDialog (id) {
            $("#" + id).modal("hide")
        },

        prependArray(value, array) {
            const newArray = array.slice()
            newArray.unshift(value)
            return newArray
        },

        getImage(imageArr, index) {
            if (index < imageArr.length) {
                return imageArr[index].replace("h=50", "w=320")
            }
            return ""
        },
        
        hasPermission: function (action) {
            if (this.user.role == "superAdmin"
                || this.user.role == "developer") {
                return true
            }

            if ((this.user.permissions?.length || 0) <= 0) {
                return false
            }

            for (let a = 0; a < this.user.permissions?.length; a++) {
                if (this.user.permissions[a].action == action) {
                    return true
                }
            }
            return false
        },

        addOrUpdateURLParam: function (key, value) {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.set(key, value)
            const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
            history.pushState(null, '', newRelativePathQuery)
        },

        getHighResImage: function (img) {
            return img.replaceAll("h=50", "w=320")
        }
    }
})

app.mount('#app')

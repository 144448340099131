<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Users Management</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Users Management</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Users (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table text-nowrap" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>Name</th>
                                	<th>Phone</th>
                                	<th>Email</th>
                                	<th>Is Paid</th>
                                	<th>Role</th>
                                	<th>Bid Limit</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">Name</th>
													<td v-text="d.name"></td>
												</tr>

												<tr>
													<th class="border-top-0">Phone</th>
													<td v-text="d.phone"></td>
												</tr>

												<tr>
													<th class="border-top-0">Email</th>
													<td v-text="d.email"></td>
												</tr>

												<tr>
													<th class="border-top-0">Is Paid</th>
													<td v-text="d.isPaid ? 'Yes' : 'No'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Role</th>
													<td style="text-transform: capitalize;" v-text="d.role.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
												</tr>

												<tr>
													<th class="border-top-0">Bid Limit</th>
													<td>
														<span v-if="d.bidLimit != 'unlimited'">&yen;</span>

														<span v-text="d.bidLimit"></span>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<template v-if="user.role == 'superAdmin' || user.role == 'developer'">
															<button type="button" class="btn btn-primary" v-on:click="loginAs(d.id)">
																Login As
															</button>

															<br /><br />
														</template>

														<template v-if="hasPermission('updateUsers')">

															<router-link v-bind:to="'/users/edit/' + d.id">
																Edit
															</router-link>

															<br />
														</template>

														<router-link v-bind:to="'/users/logs/' + d.id">
															Logs
														</router-link>

														<br />

														<template v-if="user.role == 'superAdmin' || user.role == 'developer'">

															<button type="button" class="btn btn-danger" v-on:click="deleteUser(d.id)" style="color: white;">Delete</button>

															<br /><br />

															<button v-if="d.isBan" type="button" class="btn btn-primary" v-on:click="unBanUser(d.id)">Un-ban User</button>

															<button v-else type="button" class="btn btn-primary" v-on:click="banUser(d.id)">Ban User</button>

															<p v-if="d.reasonToBan">
																Reason to ban:
																<span v-text="d.reasonToBan"></span>
															</p>

															<p v-if="d.reasonToUnBan">
																Reason to un-ban:
																<span v-text="d.reasonToUnBan"></span>
															</p>
														</template>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
	                            		<td v-text="d.name"></td>
	                            		<td v-text="d.phone"></td>
	                            		<td v-text="d.email"></td>
	                            		<td v-text="d.isPaid ? 'Yes' : 'No'"></td>
										<td style="text-transform: capitalize;" v-text="d.role.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
										<td>
											<span v-if="d.bidLimit != 'unlimited'">&yen;</span>

											<span v-text="d.bidLimit"></span>
										</td>
										<td>
											<template v-if="user.role == 'superAdmin' || user.role == 'developer'">
												<button type="button" class="btn btn-primary" v-on:click="loginAs(d.id)">
													Login As
												</button>

												<br /><br />
											</template>

											<template v-if="hasPermission('updateUsers')">
												<router-link v-bind:to="'/users/edit/' + d.id">
													Edit
												</router-link>

												<br />
											</template>

											<router-link v-bind:to="'/users/logs/' + d.id">
												Logs
											</router-link>

											<br />

											<template v-if="user.role == 'superAdmin' || user.role == 'developer'">
												<button type="button" class="btn btn-danger" v-on:click="deleteUser(d.id)" style="color: white;">Delete</button>

												<br /><br />

												<button v-if="d.isBan" type="button" class="btn btn-primary" v-on:click="unBanUser(d.id)">Un-ban User</button>

												<button v-else type="button" class="btn btn-primary" v-on:click="banUser(d.id)">Ban User</button>

												<p v-if="d.reasonToBan">
													Reason to ban:
													<span v-text="d.reasonToBan"></span>
												</p>

												<p v-if="d.reasonToUnBan">
													Reason to un-ban:
													<span v-text="d.reasonToUnBan"></span>
												</p>
											</template>
										</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>

    <iframe v-bind:src="$homeURL + '/saveLocalStorage.php'" style="display: none;"></iframe>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../../vuex/store"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "UsersComponent",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {

			doUnBanUser: function (id, reason) {
				const self = this

				return new Promise(async function (callBack) {
					const formData = new FormData()
	        		formData.append("id", id)
	        		formData.append("reason", reason)

	        		try {
	        			const response = await axios.post(
					        self.$apiURL + "/admin/users/unban",
					        formData,
					        {
					            headers: self.$headers
					        }
					    )
					 
					    if (response.data.status == "success") {
					    	swal.fire("Un-ban User", response.data.message, "success")

					    	for (let a = 0; a < self.data.length; a++) {
					    		if (self.data[a].id == id) {
					    			self.data[a].isBan = 0
					    			self.data[a].reasonToUnBan = reason
					    		}
					    	}
					    } else {
					        swal.fire("Error", response.data.message, "error")
					    }
	        		} catch (error) {
						if (error?.response?.status == 401) {
							swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						}
					} finally {
						callBack()
					}
				})
			},

			unBanUser: function (id) {
				const self = this

				swal.fire({
					title: "Enter reason to un-ban",
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Un-ban',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					},

					preConfirm: function (value) {
						return self.doUnBanUser(id, value)
					}
				}).then(async function (result) {
					if (result.isConfirmed) {
						// 
					}
				})
			},

			doBanUser: function (id, reason) {
				const self = this

				return new Promise(async function (callBack) {
					const formData = new FormData()
	        		formData.append("id", id)
	        		formData.append("reason", reason)

	        		try {
	        			const response = await axios.post(
					        self.$apiURL + "/admin/users/ban",
					        formData,
					        {
					            headers: self.$headers
					        }
					    )
					 
					    if (response.data.status == "success") {
					    	swal.fire("Ban User", response.data.message, "success")

					    	for (let a = 0; a < self.data.length; a++) {
					    		if (self.data[a].id == id) {
					    			self.data[a].isBan = 1
					    			self.data[a].reasonToBan = reason
					    		}
					    	}
					    } else {
					        swal.fire("Error", response.data.message, "error")
					    }
	        		} catch (error) {
						if (error?.response?.status == 401) {
							swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						}
					} finally {
						callBack()
					}
				})
			},

			banUser: function (id) {
				const self = this

				swal.fire({
					title: "Enter reason to ban",
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Ban',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					},

					preConfirm: function (value) {
						return self.doBanUser(id, value)
					}
				}).then(async function (result) {
					if (result.isConfirmed) {
						// 
					}
				})
			},

			deleteUser: function (id) {
				const self = this

				swal.fire({
					title: 'Delete User',
					text: "Are you sure you want to delete this user ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/users/delete",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("User Deleted", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id == id) {
						    			self.data.splice(a, 1)
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							if (self.dataTable != null) {
				            	self.dataTable.destroy()
				            }

				            setTimeout(function () {
					    		self.initialize()
					    	}, 500)
						}
					}
				})
			},

			onKeyPressSearch: function() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

			loginAs: async function (id) {
				const self = this
				const node = event.target

				swal.fire({
					title: 'Logged-in as ?',
					showDenyButton: true,
					showCancelButton: true,
					confirmButtonText: 'User',
					denyButtonText: 'Admin',
				}).then(async function (result) {
					if (result.isConfirmed || result.isDenied) {
						node.setAttribute("disabled", "disabled")

						const formData = new FormData()
		        		formData.append("id", id)

		        		try {
		        			const response = await axios.post(
						        self.$apiURL + "/admin/users/loginAs",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )

						    if (response.data.status == "success") {
						    	const token = response.data.token

						    	if (result.isConfirmed) {
						    		const iframe = document.querySelector("iframe")
							    	const wind = iframe.contentWindow
							    	const data = {
									    token: token
									}
									wind.postMessage(JSON.stringify(data), "*")

				                    window.open(self.$homeURL, '_blank')
						    	} else if (result.isDenied) {

						    		localStorage.setItem(self.$accessToken, token)
				                    self.$headers.Authorization = "Bearer " + token
				                    store.commit("setUser", response.data.user)
				                    self.$router.push("/")

				                    setTimeout(function () {
				                        global.attachEventListeners()
				                    }, 500)
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
		        		} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							node.removeAttribute("disabled")
						}
					}
				})
			},

        	getData: async function () {
        		const self = this
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/users",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#data-table").DataTable({
					// 	ordering: false
					// })
				}
	        }
        },

		mounted() {
        	this.getData()
        	document.title = "Users"
        }
	}
</script>
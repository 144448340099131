<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Dashboard</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Dashboard</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-md-4">
                <div class="white-box analytics-info">
                    <h3 class="box-title" style="display: inline-block;">Total Users</h3>
                    <ul class="list-inline two-part align-items-center mb-0" style="display: inline-block; float: right;">
                        <li>
                            <div class="spinner-border" v-if="loading"></div>
                            <span class="counter text-success" v-text="users"></span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="white-box analytics-info">
                    <h3 class="box-title" style="display: inline-block;">Today Bids</h3>
                    <ul class="list-inline two-part align-items-center mb-0" style="display: inline-block; float: right;">
                        <li>
                            <div class="spinner-border" v-if="loading"></div>
                            <span class="counter text-danger" v-text="biddings"></span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="white-box analytics-info">
                    <h3 class="box-title" style="display: inline-block;">Today Sold Cars</h3>
                    <ul class="list-inline two-part align-items-center mb-0" style="display: inline-block; float: right;">
                        <li>
                            <div class="spinner-border" v-if="loading"></div>
                            <span class="counter text-warning" v-text="soldCars"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="white-box">
                    <h2 style="margin-bottom: 30px;">Set Starting Price</h2>

                    <form v-on:submit.prevent="setStartingPrice">
                        <div class="form-group">
                            <label>Enter Car ID</label>
                            <input type="text" name="carID" class="form-control" required />
                        </div>

                        <div class="form-group">
                            <label>Starting Price</label>
                            <input type="number" min="0" name="startingPrice" class="form-control" required />
                        </div>

                        <button type="submit" class="btn btn-primary" v-bind:disabled="settingStartingPrice">Set</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row" v-if="user.role == 'developer'">
            <div class="col-md-12">
                <div class="white-box">
                    <h2 style="margin-bottom: 30px;">Custom Query</h2>

                    <form v-on:submit.prevent="onsubmitCustomQueryForm" ref="form-custom-query">
                        <div class="form-group">
                            <label>Select Table</label>
                            <select name="table" class="form-control" required>
                                <option value="main">main</option>
                                <option value="stats">stats</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Enter Query</label>
                            <input type="text" name="where" class="form-control" required />
                        </div>

                        <button type="submit" class="btn btn-primary" v-bind:disabled="runningCustomQuery">
                            Run
                            <i class="fa fa-spinner fa-spin" v-if="runningCustomQuery"></i>
                        </button>
                    </form>

                    <div class="table-responsive" style="margin-top: 20px;">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>IMAGES</th>
                                    <th>LOT</th>
                                    <th>MARKA_NAME</th>
                                    <th>MODEL_NAME</th>
                                    <th>AUCTION_DATE</th>
                                    <th>AUCTION</th>
                                    <th>MARKA_ID</th>
                                    <th>MODEL_ID</th>
                                    <th>YEAR</th>
                                    <th>ENG_V</th>
                                    <th>PW</th>
                                    <th>KUZOV</th>
                                    <th>GRADE</th>
                                    <th>COLOR</th>
                                    <th>KPP</th>
                                    <th>KPP_TYPE</th>
                                    <th>PRIV</th>
                                    <th>MILEAGE</th>
                                    <th>EQUIP</th>
                                    <th>RATE</th>
                                    <th>START</th>
                                    <th>FINISH</th>
                                    <th>STATUS</th>
                                    <th>TIME</th>
                                    <th>AVG_PRICE</th>
                                    <th>AVG_STRING</th>
                                    <th>SERIAL</th>
                                    <th>INFO</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(d, index) in queryResult.cars">
                                    <td v-text="d.ID"></td>
                                    <td>
                                        <a v-bind:href="$homeURL + '/carDetail/' + d.ID"
                                            target="_blank">
                                            <template v-for="(img, index) in 2">
                                                <img v-if="index < d.IMAGES.length" v-bind:src="d.IMAGES[index].replaceAll('h=50', 'w=320')" />
                                            </template>
                                        </a>
                                    </td>
                                    <td v-text="d.LOT"></td>
                                    <td v-text="d.MARKA_NAME"></td>
                                    <td v-text="d.MODEL_NAME"></td>
                                    <td v-text="d.AUCTION_DATE"></td>
                                    <td v-text="d.AUCTION"></td>
                                    <td v-text="d.MARKA_ID"></td>
                                    <td v-text="d.MODEL_ID"></td>
                                    <td v-text="d.YEAR"></td>
                                    <td v-text="d.ENG_V"></td>
                                    <td v-text="d.PW"></td>
                                    <td v-text="d.KUZOV"></td>
                                    <td v-text="d.GRADE"></td>
                                    <td v-text="d.COLOR"></td>
                                    <td v-text="d.KPP"></td>
                                    <td v-text="d.KPP_TYPE"></td>
                                    <td v-text="d.PRIV"></td>
                                    <td v-text="d.MILEAGE"></td>
                                    <td v-text="d.EQUIP"></td>
                                    <td v-text="d.RATE"></td>
                                    <td v-text="d.START"></td>
                                    <td v-text="d.FINISH"></td>
                                    <td v-text="d.STATUS"></td>
                                    <td v-text="d.TIME"></td>
                                    <td v-text="d.AVG_PRICE"></td>
                                    <td v-text="d.AVG_STRING"></td>
                                    <td v-text="d.SERIAL"></td>
                                    <td v-text="d.INFO"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="queryResult.pages > 0" id="pagination" style="margin-top: 20px;">
                        <nav aria-label="Page navigation">
                            <ul class="pagination">
                                <li v-for="page in queryResult.pages" v-bind:class="'page-item ' + (page == queryResult.pageNumber ? 'active' : '')">
                                    <a class="page-link" href="javascript:void(0)" v-on:click="paginateQueryResult(page)" v-text="page"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from "axios"
    import swal from "sweetalert2"
    import store from "../vuex/store"

	export default {
		name: "DashboardComponent",

        computed: {
            user() {
                return store.getters.getUser
            }
        },

        data() {
            return {
                users: "",
                biddings: "",
                soldCars: "",
                loading: false,
                settingStartingPrice: false,
                runningCustomQuery: false,
                queryResult: {
                    cars: [],
                    pages: 0,
                    pageNumber: 1
                }
            }
        },

        methods: {
            async paginateQueryResult(page) {
                this.queryResult.cars = []
                this.queryResult.pageNumber = page
                this.addOrUpdateURLParam("page", page)
                this.doCustomQuery()
            },

            onsubmitCustomQueryForm() {
                this.queryResult.cars = []
                this.queryResult.pageNumber = 1
                this.addOrUpdateURLParam("page", 1)
                this.doCustomQuery()
            },

            async doCustomQuery() {
                this.runningCustomQuery = true

                const form = this.$refs["form-custom-query"]
                const formData = new FormData(form)
                formData.append("page", this.queryResult.pageNumber)

                try {
                    const response = await axios.post(
                        this.$apiURL + "/admin/runCustomQuery",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.queryResult.cars = response.data.cars
                        this.queryResult.pages = response.data.pages
                    } else {
                        swal.fire("Error", response.data.message, "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.runningCustomQuery = false
                }
            },

            async setStartingPrice() {
                this.settingStartingPrice = true

                const form = event.target
                const formData = new FormData(form)

                try {
                    const response = await axios.post(
                        this.$apiURL + "/admin/auctionCars/setStartingPrice",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    swal.fire("Starting Price", response.data.message, response.data.status)
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.settingStartingPrice = false
                }
            },

            async getData() {
                this.loading = true

                try {
                    const response = await axios.post(
                        this.$apiURL + "/admin/dashboard",
                        null,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.users = response.data.users
                        this.biddings = response.data.biddings
                        this.soldCars = response.data.soldCars
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            },

            async markNotificationsAsRead () {
                const formData = new FormData()
                formData.append("type", "startingPrice")

                try {
                    const response = await axios.post(
                        this.$apiURL + "/markNotificationsAsRead",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        store.commit("setStartingPrice", 0)
                    } else {
                        // swal.fire("Error", response.data.message, "error")
                    }
                } catch (error) {
                    // if (error?.response?.status == 401) {
                    //  swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    // }
                }
            }
        },

        mounted() {
            this.getData()
            document.title = "Dashboard"
            this.markNotificationsAsRead()
        }
	}
</script>
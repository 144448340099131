<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Edit Country</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Edit Country</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<div v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

					<form v-on:submit.prevent="updateData" enctype="multipart/form-data" v-if="data != null">
						<div class="form-group">
							<label class="form-label">Logo</label>
							<input type="file" name="logo" ref="logo" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.logo">
								<div class="col-md-3">
									<img v-bind:src="data.logo" style="width: 100%; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.logo, 'logo')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Slides</label>
							
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>Title</th>
										<th>Description</th>
										<th>Image</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(slide, index) in slides" v-bind:key="'country-slide-' + index">
										<td>
											<input type="text" name="slideTitle" v-model="slide.title" />
										</td>

										<td>
											<input type="text" name="slideDescription" v-model="slide.description" />
										</td>

										<td>
											<input type="file" name="slideImage" accept="image/*" v-on:change="slideImageSelected(index)" />

											<img v-if="slide.image" v-bind:src="slide.image" style="width: 200px; height: 200px; object-fit: contain; display: block; margin-top: 10px;" />
										</td>

										<td>
											<button type="button" class="btn btn-success btn-sm" style="color: white;" v-on:click="saveSlide(index)">Save</button>&nbsp;

											<button type="button" class="btn btn-danger btn-sm" style="color: white;" v-on:click="deleteSlide(index)">Delete</button>
										</td>
									</tr>
								</tbody>
							</table>

							<button type="button" class="btn btn-info btn-sm" v-on:click="addSlide" style="color: white;">Add</button>
						</div>

						<!-- <div class="form-group">
							<label class="form-label">Slides</label>
							<input type="file" multiple name="slides[]" placeholder="Select Slides" class="form-control" ref="slides" />

							<div class="row" style="margin-top: 10px;">
								<div class="col-md-3" v-for="(slide, index) in data.slides">
									<img v-bind:src="slide" style="width: 100%; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteSlide(slide)" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div> -->

                		<div class="form-group">
							<label class="form-label">Name</label>
							<input type="text" name="name" placeholder="Enter Name" class="form-control" v-model="data.name" />
						</div>

						<div class="form-group">
							<label class="form-label">Region</label>
							<input type="text" name="region" placeholder="Enter Region" class="form-control" v-model="data.region" />
						</div>

						<div class="form-group">
							<label class="form-label">Country Code</label>
							<input type="text" name="countryCode" placeholder="Enter Country Code" class="form-control" v-model="data.countryCode" />
						</div>

						<!-- <div class="form-group">
							<label class="form-label">Port</label>
							<input type="text" name="port" placeholder="Enter Port" class="form-control" v-model="data.port" />
						</div> -->

						<div class="form-group">
							<label class="form-label">Ports</label>
							
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>Port</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(port, index) in ports" v-bind:key="'country-port-' + index">
										<td>
											<input type="text" v-model="ports[index]" />
										</td>

										<td>
											<button type="button" class="btn btn-success btn-sm" style="color: white;" v-on:click="savePort(index)">Save</button>&nbsp;

											<button type="button" class="btn btn-danger btn-sm" style="color: white;" v-on:click="deletePort(index)">Delete</button>
										</td>
									</tr>
								</tbody>
							</table>

							<button type="button" class="btn btn-info btn-sm" v-on:click="addPort" style="color: white;">Add</button>
						</div>

						<div class="form-group">
							<label class="form-label">Shipping Line</label>
							<input type="text" name="shippingLine" placeholder="Enter Shipping Line" class="form-control" v-model="data.shippingLine" />
						</div>

						<div class="form-group">
							<label class="form-label">Flag</label>
							<input type="file" name="flag" ref="flag" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.flag">
								<div class="col-md-3">
									<img v-bind:src="data.flag" style="width: 300px; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.flag, 'flag')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Flag Big</label>
							<input type="file" name="flagBig" ref="flagBig" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.flagBig">
								<div class="col-md-3">
									<img v-bind:src="data.flagBig" style="width: 300px; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.flagBig, 'flagBig')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Map</label>
							<input type="file" name="map" ref="map" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.map">
								<div class="col-md-3">
									<img v-bind:src="data.map" style="width: 300px; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.map, 'map')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Currency</label>
							<input type="text" name="currency" placeholder="Enter Currency" class="form-control" v-model="data.currency" />
						</div>

						<div class="form-group">
							<label class="form-label">Policy</label>
							<textarea name="policy" placeholder="Enter Policy" class="form-control" v-model="data.policy"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Intro</label>
							<textarea name="intro" placeholder="Enter Intro" class="form-control" v-model="data.intro"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Age Restriction</label>
							<textarea name="ageRestrictions" placeholder="Enter Age Restriction" class="form-control" v-model="data.ageRestrictions"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Inspection</label>
							<textarea name="inspection" placeholder="Enter Inspection" class="form-control" v-model="data.inspection"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Import Duties and Taxes</label>
							<textarea name="importDutiesTaxes" placeholder="Enter Import Duties and Taxes" class="form-control" v-model="data.importDutiesTaxes"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">TimeZone</label>
							<input type="text" name="timeZone" placeholder="Enter TimeZone" class="form-control" v-model="data.timeZone" />
						</div>

						<div class="form-group">
							<label class="form-label">Shipping Time</label>
							<input type="text" name="shippingTime" placeholder="Enter Shipping Time" class="form-control" v-model="data.shippingTime" />
						</div>

						<div class="form-group">
							<label class="form-label">Status</label>
							<select name="status" class="form-control" v-model="data.status">
								<option value="active">Active</option>
								<option value="inactive">De-activate</option>
							</select>
						</div>

						<div class="form-group">
							<label class="form-label">Cars</label>
							
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>Make</th>
										<th>Model</th>
										<th>Year</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(car, index) in cars" v-bind:key="'country-car-' + index">
										<td>
											<select v-on:change="onChangeMake" v-model="car.make">
												<option value="">ALL MAKES</option>
												<option v-for="make of makes" v-bind:value="make" v-text="make" v-bind:selected="make == car.make"></option>
											</select>
										</td>

										<td>
											<select v-model="car.model">
												<option v-for="model of (models[car.make] || [])" v-bind:value="model" v-text="model" v-bind:selected="model == car.model"></option>
											</select>
										</td>

										<td>
											<input type="number" v-model="car.year" />
										</td>

										<td>
											<button type="button" class="btn btn-success btn-sm" style="color: white;" v-on:click="saveCar(index)">Save</button>&nbsp;

											<button type="button" class="btn btn-danger btn-sm" style="color: white;" v-on:click="deleteCar(index)">Delete</button>
										</td>
									</tr>
								</tbody>
							</table>

							<button type="button" class="btn btn-info btn-sm" v-on:click="addCar" style="color: white;">Add</button>
						</div>

						<div class="form-group">
							<label class="form-label">Categories (on top navbar dropdown)</label>
							
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>Make</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(category, index) in categories" v-bind:key="'country-category-' + index">
										<td>
											<select v-model="categories[index]">
												<option value="">ALL MAKES</option>
												<option v-for="make of makes" v-bind:value="make" v-text="make" v-bind:selected="make == category"></option>
											</select>
										</td>

										<td>
											<button type="button" class="btn btn-success btn-sm" style="color: white;" v-on:click="saveCategory(index)">Save</button>&nbsp;

											<button type="button" class="btn btn-danger btn-sm" style="color: white;" v-on:click="deleteCategory(index)">Delete</button>
										</td>
									</tr>
								</tbody>
							</table>

							<button type="button" class="btn btn-info btn-sm" v-on:click="addCategory" style="color: white;">Add</button>
						</div>

						<div class="form-group">
							<label class="form-label">Banner cars</label>
							
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>Car ID</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(bannerCar, index) in bannerCars" v-bind:key="'country-bannerCar-' + index">
										<td>
											<input type="text" name="carId" v-model="bannerCars[index]" />
										</td>

										<td>
											<button type="button" class="btn btn-success btn-sm" style="color: white;" v-on:click="saveBannerCar(index)">Save</button>&nbsp;

											<button type="button" class="btn btn-danger btn-sm" style="color: white;" v-on:click="deleteBannerCar(index)">Delete</button>
										</td>
									</tr>
								</tbody>
							</table>

							<button type="button" class="btn btn-info btn-sm" v-on:click="addBannerCar" style="color: white;">Add</button>
						</div>

						<div class="form-group">
							<label class="form-label">FAQs</label>
							
							<table class="table table-bordered">
								<thead>
									<tr>
										<th>Question</th>
										<th>Answer</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="(faq, index) in faqs" v-bind:key="'country-faq-' + index">
										<td>
											<input type="text" v-model="faq.question" />
										</td>

										<td>
											<input type="text" v-model="faq.answer" />
										</td>

										<td>
											<button type="button" class="btn btn-success btn-sm" style="color: white;" v-on:click="saveFAQ(index)">Save</button>&nbsp;

											<button type="button" class="btn btn-danger btn-sm" style="color: white;" v-on:click="deleteFAQ(index)">Delete</button>
										</td>
									</tr>
								</tbody>
							</table>

							<button type="button" class="btn btn-info btn-sm" v-on:click="addFAQ" style="color: white;">Add</button>
						</div>

						<button type="submit" name="submit" class="btn btn-warning" style="color: black;">
							Edit Country
							<i class="fa fa-spinner fa-spin" v-if="isEditing"></i>
						</button>
                	</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "EditCountryComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				data: null,
				loading: false,
				isEditing: false,
				slides: [],
				cars: [],
				makes: [],
				models: [],
				faqs: [],
				categories: [],
				ports: [],
				bannerCars: []
			}
		},

		methods: {
			deleteBannerCar(index) {
				const self = this
				const target = event.target

				if (self.bannerCars[index] == "") {
					self.bannerCars.splice(index, 1)
					return
				}

				swal.fire({
					title: "Delete Banner Car ?",
					text: "Are you sure you want to delete this banner car ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, delete it!"
				}).then(async function (result) {
					if (result.isConfirmed) {
						target.setAttribute("disabled", "disabled")

						const formData = new FormData()
						formData.append("id", self.id)
						formData.append("bannerCar", self.bannerCars[index])

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/countries/deleteBannerCar",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								self.bannerCars.splice(index, 1)
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							console.log(error)
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
						}
					}
				})
			},

			async saveBannerCar(index) {
				const bannerCar = this.bannerCars[index]

				const target = event.target
				target.setAttribute("disabled", "disabled")

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("bannerCars", JSON.stringify(this.bannerCars))

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/saveBannerCars",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						// 
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
				}
			},

			addBannerCar() {
				this.bannerCars.push("")
			},

			deletePort(index) {
				const self = this
				const target = event.target

				if (self.ports[index] == "") {
					self.ports.splice(index, 1)
					return
				}

				swal.fire({
					title: "Delete Port ?",
					text: "Are you sure you want to delete this port ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, delete it!"
				}).then(async function (result) {
					if (result.isConfirmed) {
						target.setAttribute("disabled", "disabled")

						const formData = new FormData()
						formData.append("id", self.id)
						formData.append("port", self.ports[index])

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/countries/deletePort",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								self.ports.splice(index, 1)
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							console.log(error)
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
						}
					}
				})
			},

			async savePort(index) {
				const port = this.ports[index]

				const target = event.target
				target.setAttribute("disabled", "disabled")

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("ports", JSON.stringify(this.ports))

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/savePort",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						// 
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
				}
			},

			addPort() {
				this.ports.push("")
			},

			deleteCategory(index) {
				const self = this
				const target = event.target

				swal.fire({
					title: "Delete Category ?",
					text: "Are you sure you want to delete this category ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, delete it!"
				}).then(async function (result) {
					if (result.isConfirmed) {
						target.setAttribute("disabled", "disabled")

						const formData = new FormData()
						formData.append("id", self.id)
						formData.append("category", self.categories[index])

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/countries/deleteCategory",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								self.categories.splice(index, 1)
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							console.log(error)
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
						}
					}
				})
			},

			async saveCategory(index) {
				const category = this.categories[index]

				const target = event.target
				target.setAttribute("disabled", "disabled")

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("category", category)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/addCategory",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						// 
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
				}
			},

			addCategory() {
				this.categories.push("")
			},

			deleteFAQ(index) {
				const self = this
				const target = event.target

				if (self.faqs[index].id <= 0) {
					self.faqs.splice(index, 1)
					return
				}

				swal.fire({
					title: "Delete FAQ ?",
					text: "Are you sure you want to delete this FAQ ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, delete it!"
				}).then(async function (result) {
					if (result.isConfirmed) {
						target.setAttribute("disabled", "disabled")

						const formData = new FormData()
						formData.append("id", self.faqs[index].id)

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/countries/deleteFAQ",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								self.faqs.splice(index, 1)
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							console.log(error)
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
						}
					}
				})
			},

			async saveFAQ(index) {
				const faq = this.faqs[index]

				const target = event.target
				target.setAttribute("disabled", "disabled")

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("faqId", faq.id)
				formData.append("question", faq.question)
				formData.append("answer", faq.answer)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/addFAQ",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						// 
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
				}
			},

			addFAQ() {
				this.faqs.push({
					id: 0,
					question: "",
					answer: ""
				})
			},

			async onChangeMake() {
				const make = event.target.value

				if (typeof this.models[make] === "undefined") {
					const formData = new FormData()
					formData.append("marka", make)

					try {
						const response = await axios.post(
							this.$apiURL + "/fetchModels",
							formData,
							{
								headers: self.$headers
							}
						)

						if (response.data.status == "success") {
							this.models[make] = response.data.models
						} else {
							// swal.fire("Error", response.data.message, "error")
						}
					} catch (error) {
						// if (error?.response?.status == 401) {
						// 	swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						// }
					}
				}
			},

			deleteCar(index) {
				const self = this
				const target = event.target

				if (self.cars[index].id <= 0) {
					self.cars.splice(index, 1)
					return
				}

				swal.fire({
					title: "Delete Car ?",
					text: "Are you sure you want to delete this car ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, delete it!"
				}).then(async function (result) {
					if (result.isConfirmed) {
						target.setAttribute("disabled", "disabled")

						const formData = new FormData()
						formData.append("id", self.cars[index].id)

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/countries/deleteCar",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								self.cars.splice(index, 1)
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							console.log(error)
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
						}
					}
				})
			},

			async saveCar(index) {
				const car = this.cars[index]

				const target = event.target
				target.setAttribute("disabled", "disabled")

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("carId", car.id)
				formData.append("make", car.make)
				formData.append("model", car.model)
				formData.append("year", car.year)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/addCar",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						// 
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
				}
			},

			addCar() {
				this.cars.push({
					id: 0,
					make: "",
					model: "",
					year: ""
				})
			},

			async saveSlide(index) {
				const slide = this.slides[index]

				const target = event.target
				target.setAttribute("disabled", "disabled")

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("slideId", slide.id)
				formData.append("title", slide.title)
				formData.append("description", slide.description)
				formData.append("image", slide.image)
				formData.append("imageName", slide.imageName)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/addSlide",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						const slideImages = document.querySelectorAll("input[name='slideImage']")
						for (let a = 0; a < slideImages.length; a++) {
							slideImages[a].value = null
						}
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
				}
			},

			deleteSlide(index) {
				const self = this
				const target = event.target

				swal.fire({
					title: "Delete Slide ?",
					text: "Are you sure you want to delete this slide ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, delete it!"
				}).then(async function (result) {
					if (result.isConfirmed) {
						target.setAttribute("disabled", "disabled")

						const formData = new FormData()
						formData.append("id", self.id)
						formData.append("slide", self.slides[index].image)

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/countries/deleteSlide",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								self.slides.splice(index, 1)
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							console.log(error)
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
						}
					}
				})
			},

			slideImageSelected(index) {
				const self = this
				const files = event.target.files
				if (files.length > 0) {
					var fileReader = new FileReader()

					fileReader.onload = function (event) {
						self.slides[index].imageName = files[0].name
						self.slides[index].image = event.target.result
					}

					fileReader.readAsDataURL(files[0])
				}
			},

			addSlide() {
				this.slides.push({
					id: 0,
					title: "",
					description: "",
					image: "",
					imageName: ""
				})
			},

			async deleteFile(filePath, type) {
				const target = event.target
				target.setAttribute("disabled", "disabled")
				target.querySelector("i").style.display = ""

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("filePath", filePath)
				formData.append("type", type)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/deleteFile",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data[type] = ""
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
					target.querySelector("i").style.display = "none"
				}
			},

			async updateData() {
				this.isEditing = true

				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/update",
						formData,
						{
							headers: this.$headers
						}
					)

					swal.fire("Edit Country", response.data.message, response.data.status)

					if (response.data.status == "success") {
						this.$refs["logo"].value = ""
						this.$refs["slides"].value = ""
						this.$refs["flag"].value = ""
						this.$refs["flagBig"].value = ""
						this.$refs["map"].value = ""
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isEditing = false
				}
			},

			async getData() {
				const self = this
				this.loading = true

				const formData = new FormData()
				formData.append("id", this.id)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/fetch",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.slides = response.data.slides
						this.cars = response.data.cars
						this.faqs = response.data.faqs
						this.makes = response.data.makes
						this.models = response.data.models
						this.categories = response.data.categories
						this.ports = response.data.ports || []
						this.bannerCars = response.data.bannerCars || []
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
        	this.getData()
        	document.title = "Edit Country"
        }
	}
</script>
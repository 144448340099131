<template>
    <div v-html="content"></div>
</template>

<script>

    import axios from "axios"
    import swal from "sweetalert2"

    export default {
        name: "ReadContactUsMail",

        data() {
            return {
                id: this.$route.params.id || 0,
                content: "",
                loading: false
            }
        },

        methods: {
            async getData () {
                this.loading = true

                const formData = new FormData()
                formData.append("id", this.id)

                try {
                    const response = await axios.post(
                        this.$apiURL + "/admin/fetchContactUsContent",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                 
                    this.content = response.data
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
        },

        mounted() {
            this.getData()
            document.querySelector(".modal-backdrop.show").remove()
        }
    }
</script>
<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Panoramic</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Panoramic</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

    	<div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
				    <form v-on:submit.prevent="addCarPanoramic" enctype="multipart/form-data">

				    	<div class="form-group">
				    		<label>Car ID</label>
				    		<input type="text" name="id" class="form-control" required />
				    	</div>

				    	<div class="form-group">
				    		<label>Upload video</label>
				    		<input type="file" name="video" accept="video/*" class="form-control" required />
				    	</div>

				    	<input type="submit" name="submit" class="btn btn-info" value="Add Car"
				    		style="color: white;" />
				    </form>
                </div>
            </div>
        </div>

        <div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Panoramic (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table text-nowrap" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>LOT</th>
                                	<th>Car</th>
                                	<th>Auction</th>
                                	<th>Image</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data" v-bind:key="'cars-' + d.CAR_ID">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">LOT</th>
													<td v-text="d.LOT"></td>
												</tr>

												<tr>
													<th class="border-top-0">Car</th>
													<td>
														<a v-bind:href="$homeURL + '/car-details.php?id=' + d.CAR_ID"
				                            				target="_blank" 
				                            				v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></a>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Auction</th>
													<td v-html="d.AUCTION + '<br />' + d.AUCTION_DATE.split(' ')[0]"></td>
												</tr>

												<tr>
													<th class="border-top-0">Image</th>
													<td>
														<template v-for="(i, index) in 2">
				                            				<img v-if="d.IMAGES.length > index" v-bind:src="d.IMAGES[index]" style="width: 100px;" />
				                            			</template>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
	                            		<td v-text="d.LOT"></td>
	                            		<td>
	                            			<a v-bind:href="$homeURL + '/car-details.php?id=' + d.CAR_ID"
	                            				target="_blank" 
	                            				v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></a>
	                            		</td>
	                            		<td v-html="d.AUCTION + '<br />' + d.AUCTION_DATE.split(' ')[0]"></td>
	                            		<td>
	                            			<template v-for="(i, index) in 2">
	                            				<img v-if="d.IMAGES.length > index" v-bind:src="d.IMAGES[index]" style="width: 100px;" />
	                            			</template>
	                            		</td>	                            		
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../vuex/store"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "PanoramicComponent",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {
			async addCarPanoramic() {
				const self = this

				const form = event.target
				form.submit.setAttribute("disabled", "disabled")
				form.submit.innerHTML = "Saving..."
				const formData = new FormData(form)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/panoramic/add",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	form.reset()
				    	form.video = null

				    	this.data.unshift(response.data.auction_car)

				    	if (this.dataTable != null) {
			            	this.dataTable.destroy()
			            }

			            setTimeout(function () {
				    		self.initialize()
				    	}, 500)
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.data?.message) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					form.submit.removeAttribute("disabled")
					form.submit.innerHTML = "Add Car"
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

			getData: async function () {
        		const self = this
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/panoramic",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#data-table").DataTable({
					// 	ordering: false
					// })
				}
	        }
		},

		mounted() {
        	this.getData()
        	document.title = "Panoramic"
        }
	}
</script>
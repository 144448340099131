<template>

    <div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Login</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Login</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="white-box">
                    <h3 class="box-title">Login</h3>

                    <form v-on:submit.prevent="doLogin">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" placeholder="Email" />
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Password" name="password" />
                        </div>
                        <button type="submit" v-bind:disabled="loggingIn" class="btn login-form__btn submit w-100">
                            <span v-if="loggingIn">Loading...</span>
                            <span v-else>Sign In</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // import "../assets/plugins/bower_components/chartist/dist/chartist.min.css"
    // import "../assets/plugins/bower_components/chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css"
    import "../assets/css/style.min.css"

	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../vuex/store"

	export default {
		name: "LoginComponent",

		data() {
			return {
                loggingIn: false
			}
		},

		methods: {

            doLogin: async function () {
                this.loggingIn = true

                const form = event.target
                const formData = new FormData(form)

                const response = await axios.post(
                    this.$apiURL + "/login",
                    formData
                )

                this.loggingIn = false

                if (response.data.status == "success") {
                    const token = response.data.token
                    localStorage.setItem(this.$accessToken, token)
                    this.$headers.Authorization = "Bearer " + token
                    store.commit("setUser", response.data.user)

                    window.location.href = "/"

                    // setTimeout(function () {
                    //     global.attachEventListeners()
                    // }, 500)
                    
                    // this.$router.push("/")
                } else {
                    swal.fire("Sign In", response.data.message, response.data.status)
                }
            },
        },

        mounted() {
            document.title = "Login"
        }
	}
</script>
<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Bids Management</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Bids Management</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Select Date</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="date" class="form-control p-0 border-0 date" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Bids (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table table-bordered text-nowrap" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>User</th>
                                	<th>LOT</th>
                                	<th>IMAGES</th>
                                	<th>Time</th>
                                	<th>Year</th>
                                	<th>Name</th>
                                	<th>Chassis</th>
                                	<th>Package</th>
                                	<th>Mileage</th>
                                	<th>Engine</th>
                                	<th>Transmission</th>
                                	<th>Grade</th>
                                	<th>Color</th>
                                	<th>Starting Price</th>
                                	<th>Bid Price</th>
                                	<th>Comments</th>
                                	<th>Status</th>
                                	<th>Finish</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
			                    					<th class="border-top-0">User</th>
													<td>
														<router-link v-bind:to="'/users/edit/' + d.user.id" v-text="d.user.name + ' | ' + d.user.email">
														</router-link>
													</td>
			                    				</tr>

	                            				<tr>
													<th class="border-top-0">LOT</th>
													<td v-text="d.auctionCar.LOT"></td>
												</tr>

												<tr>
													<th class="border-top-0">Images</th>
													<td>
														<a v-bind:href="$homeURL + '/car-details.php?id=' + d.auctionCar.CAR_ID" target="_blank">
															<div class="row">
																<div class="col-md-3" v-for="img in d.auctionCar.IMAGES">
																</div>
															</div>

															<template v-for="(img, index) in 3">
																<img v-if="index < d.auctionCar.IMAGES.length" v-bind:src="d.auctionCar.IMAGES[index].replaceAll('h=50', 'w=320')" style="width: 100px;" />
															</template>
														</a>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Time</th>
													<td v-text="d.createdAt"></td>
												</tr>

												<tr>
													<th class="border-top-0">Year</th>
													<td v-text="d.auctionCar.YEAR"></td>
												</tr>

												<tr>
													<th class="border-top-0">Name</th>
													<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
												</tr>

												<tr>
													<th class="border-top-0">Chassis</th>
													<td v-text="d.auctionCar.KUZOV"></td>
												</tr>

												<tr>
													<th class="border-top-0">Package</th>
													<td v-html="d.auctionCar.GRADE"></td>
												</tr>

												<tr>
													<th class="border-top-0">Mileage</th>
													<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Displace</th>
													<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Transmission</th>
													<td v-text="d.auctionCar.KPP"></td>
												</tr>
												
												<tr>
													<th class="border-top-0">Grade</th>
													<td v-text="d.auctionCar.RATE"></td>
												</tr>

												<tr>
													<th class="border-top-0">Color</th>
													<td v-text="d.auctionCar.COLOR"></td>
												</tr>
												
												<tr>
													<th class="border-top-0">Starting Price</th>
													<td v-html="'&yen;' + d.auctionCar.START"></td>
												</tr>

												<tr>
													<th class="border-top-0">Bid Price</th>
													<td>
														<span v-html="'&yen;' + d.bidAmount"></span>
														
					                    				<p v-if="d.isTrashed" style="color: red;">Deleted</p>

					                    				<p v-if="d.isBookmark" style="color: #a6c76c;">Wishlist</p>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Comments</th>
													<td>
														<span v-text="d.comments"></span>

														<button v-if="d.type == 'bid'" type="button" class="btn btn-default btn-sm btn-comments" v-on:click="addBidCommentModal(d.id)" style="margin-left: 10px;">
	                                                        <i class="fa fa-comments"></i>
	                                                    </button>

	                                                    <p v-if="d.adminComments != null" v-text="'Admin comments: ' + d.adminComments"></p>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Status</th>
													<td>
														<template v-if="d.isAwarded">
															Awarded
														</template>

														<p v-else v-text="d.auctionCar.STATUS"></p>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Result</th>
													<td v-if="d.auctionCar.FINISH != '0'" v-html="'&yen;' + d.auctionCar.FINISH"></td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<div class="dropdown">
															<button class="btn btn-secondary dropdown-toggle" style="color: white;" type="button" v-bind:id="'dropdownMenuActions' + d.id" data-bs-toggle="dropdown" aria-expanded="false">
																Actions
															</button>

															<ul class="dropdown-menu" v-bind:aria-labelledby="'dropdownMenuActions' + d.id">
																
																<template v-if="d.type == 'bid'">
																	<li v-if="!d.isAwarded">
																		<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="awardBid(d.id)">Award</a>
																	</li>

																	<li v-if="d.isAwarded">
																		<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="unAwardBid(d.id)">Un-award</a>
																	</li>

																	<li>
																		<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="updateBidPrice(d.id)">Update Bid Price</a>
																	</li>
																</template>

																<template v-else>
																	<li v-if="!d.isAwarded">
																		<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="awardWishlist(d.id)">Award Wishlist</a>
																	</li>
																</template>

																<li>
																	<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="setStatusAndFinalPrice('Sold', d.auctionCar.CAR_ID)">Sold</a>
																</li>

																<li>
																	<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="setStatusAndFinalPrice('Not Sold', d.auctionCar.CAR_ID)">Not Sold</a>
																</li>

																<li>
																	<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="setStatusAndFinalPrice('Sold by Nego', d.auctionCar.CAR_ID)">Sold by Nego</a>
																</li>

																<li>
																	<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="auctionCancelled(d.auctionCar.CAR_ID)">Auction Canceled</a>
																</li>

																<li v-if="d.type == 'bid'">
																	<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="deleteBid(d.id)">Delete Bid</a>
																</li>
															</ul>
														</div>

														<p style="margin-top: 10px;" v-if="d.type == 'bid'">
															<a href="javascript:void(0)" v-on:click="showBidLogs(d.id)">
																Bid Logs
															</a>
														</p>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
	                            		<td>
											<router-link v-bind:to="'/users/edit/' + d.user.id" v-text="d.user.name + ' | ' + d.user.email">
											</router-link>
										</td>

										<td v-text="d.auctionCar.LOT"></td>

										<td>
											<a v-bind:href="$homeURL + '/car-details.php?id=' + d.auctionCar.CAR_ID" target="_blank">
												<template v-for="(img, index) in 3">
													<img v-if="index < d.auctionCar.IMAGES.length" v-bind:src="d.auctionCar.IMAGES[index].replaceAll('h=50', 'w=320')" style="width: 200px;" />
												</template>
											</a>
										</td>

										<td v-text="d.createdAt"></td>
										<td v-text="d.auctionCar.YEAR"></td>
										<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
										<td v-text="d.auctionCar.KUZOV"></td>
										<td v-html="d.auctionCar.GRADE"></td>
										<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
										<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
										<td v-text="d.auctionCar.KPP"></td>
										<td v-text="d.auctionCar.RATE"></td>
										<td v-text="d.auctionCar.COLOR"></td>
										<td v-html="'&yen;' + d.auctionCar.START"></td>
										<td>
											<span v-html="'&yen;' + d.bidAmount"></span>

											<p v-if="d.isTrashed" style="color: red;">Deleted</p>

											<p v-if="d.isBookmark" style="color: #a6c76c;">Wishlist</p>
										</td>
										<td>
											<span v-text="d.comments"></span>

											<button v-if="d.type == 'bid'" type="button" class="btn btn-default btn-sm btn-comments" v-on:click="addBidCommentModal(d.id)" style="margin-left: 10px;">
                                                <i class="fa fa-comments"></i>
                                            </button>

                                            <p v-if="d.adminComments != null" v-text="'Admin comments: ' + d.adminComments"></p>
										</td>
										<td>
											<template v-if="d.isAwarded">
												Awarded
											</template>

											<p v-else v-text="d.auctionCar.STATUS"></p>
										</td>

										<td v-html="'&yen;' + d.auctionCar.FINISH"></td>

										<td>
											<div class="dropdown">
												<button class="btn btn-secondary dropdown-toggle" style="color: white;" type="button" v-bind:id="'dropdownMenuActions' + d.id" data-bs-toggle="dropdown" aria-expanded="false">
													Actions
												</button>

												<ul class="dropdown-menu" v-bind:aria-labelledby="'dropdownMenuActions' + d.id">
													
													<template v-if="d.type == 'bid'">
														<li v-if="!d.isAwarded">
															<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="awardBid(d.id)">Award</a>
														</li>

														<li v-if="d.isAwarded">
															<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="unAwardBid(d.id)">Un-award</a>
														</li>

														<li>
															<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="updateBidPrice(d.id)">Update Bid Price</a>
														</li>
													</template>

													<template v-else>
														<li v-if="!d.isAwarded">
															<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="awardWishlist(d.id)">Award Wishlist</a>
														</li>
													</template>

													<li>
														<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="setStatusAndFinalPrice('Sold', d.auctionCar.CAR_ID)">Sold</a>
													</li>

													<li>
														<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="setStatusAndFinalPrice('Not Sold', d.auctionCar.CAR_ID)">Not Sold</a>
													</li>

													<li>
														<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="setStatusAndFinalPrice('Sold by Nego', d.auctionCar.CAR_ID)">Sold by Nego</a>
													</li>

													<li>
														<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="auctionCancelled(d.auctionCar.CAR_ID)">Auction Canceled</a>
													</li>

													<li v-if="d.type == 'bid'">
														<a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="deleteBid(d.id)">Delete Bid</a>
													</li>
												</ul>
											</div>

											<p style="margin-top: 10px;" v-if="d.type == 'bid'">
												<a href="javascript:void(0)" v-on:click="showBidLogs(d.id)">
													Bid Logs
												</a>
											</p>

											<p style="margin-top: 10px;">
												<a href="javascript:void(0)" v-on:click="showCarLogs(d.auctionCarId)">
													Car Logs
												</a>
											</p>
										</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal" id="bidLogsModal">
    	<div class="modal-dialog modal-lg">
    		<div class="modal-content">
    			<div class="modal-header">
    				<h3 class="modal-title">Bid Logs</h3>

    				<button type="button" class="close" v-on:click="closeBidLogsModal" style="background: none; border: none;">
			          <span>&times;</span>
			        </button>
    			</div>

    			<div class="modal-body" style="overflow-x: scroll;">
    				<div class="text-center" v-if="loadingBidLogs">
    					<div class="spinner-border"></div>
    				</div>

    				<table class="table table-bordered table-responsive" id="bid-logs-data">
    					<thead>
    						<tr>
    							<th>Logged By</th>
                            	<th>Action</th>
                            	<th>Date and Time</th>
    						</tr>
    					</thead>

    					<tbody>
    						<tr v-for="(d, index) in bidLogs" v-bind:key="d.id">
    							<td>
									<router-link v-bind:to="'/users/edit/' + d.loggedBy" v-text="d.name + ' | ' + d.email" v-on:click="closeBidLogsModal">
									</router-link>
								</td>

								<td v-html="d.action"></td>
								<td v-text="d.created_at"></td>
    						</tr>
    					</tbody>
    				</table>

    				<div v-if="bidLogPages > 0">
    					<nav>
    						<ul class="pagination">
    							<li v-bind:class="'page-item ' + (page == bidLogPage ? 'active' : '')" v-for="page in bidLogPages">
    								<a href="javascript:void(0)" v-on:click="paginateBidLogs(page)" v-text="page" class="page-link"></a>
    							</li>
    						</ul>
    					</nav>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>

    <div class="modal" id="carLogsModal">
    	<div class="modal-dialog modal-lg">
    		<div class="modal-content">
    			<div class="modal-header">
    				<h3 class="modal-title">Car Logs</h3>

    				<button type="button" class="close" data-dismiss="modal" style="background: none; border: none;" v-on:click="closeCarLogsModal">
    					<span>&times;</span>
    				</button>
    			</div>

    			<div class="modal-body" style="overflow-x: scroll;">
    				<table class="table table-bordered table-responsive" id="car-logs-data">
    					<thead>
    						<tr>
    							<th>Logged By</th>
    							<th>Action</th>
    							<th>Date &amp; Time</th>
    						</tr>
    					</thead>

    					<tbody>
    						<tr v-for="d in carLogs">
    							<td>
    								<router-link v-bind:to="'/users/edit/' + d.loggedBy" v-text="d.name + ' | ' + d.email" v-on:click="closeCarLogsModal"></router-link>
    							</td>

    							<td v-html="d.action"></td>
    							<td v-text="d.created_at"></td>
    						</tr>
    					</tbody>
    				</table>
    			</div>
    		</div>
    	</div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../../vuex/store"

    import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "BidsComponent",

		data() {
			return {
				loading: false,
				data: [],
				date: "",
				dataTable: null,

				loadingBidLogs: false,
				bidLogPage: 1,
				bidLogs: [],
				bidLogPages: 0,
				bidLogsDataTable: null,
				bidLogId: 0,

				carLogId: 0,
				carLogLoading: false,
				carLogs: [],
				carLogsDataTable: null
			}
		},

		methods: {

			closeCarLogsModal() {
				$("#carLogsModal").modal("hide")
			},

			async showCarLogs(id) {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				this.carLogId = id
				this.carLogLoading = true
				$("#carLogsModal").modal("show")

				if (this.carLogsDataTable != null) {
					this.carLogsDataTable.destroy()
				}

				const formData = new FormData()
				formData.append("id", this.carLogId)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/auctionCars/logs",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.carLogs = response.data.data
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.carLogLoading = false

					setTimeout(function () {
						self.carLogsDataTable = jQuery("#car-logs-data").DataTable({
							ordering: false
						})
					}, 500)
				}
			},

			closeBidLogsModal() {
				$("#bidLogsModal").modal("hide")
			},

			paginateBidLogs(page) {
				this.bidLogPage = page
				this.showBidLogs(this.bidLogId)
			},

			async showBidLogs(id) {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				$("#bidLogsModal").modal("show")
				this.loadingBidLogs = true
				this.bidLogId = id

				if (this.bidLogsDataTable != null) {
					this.bidLogsDataTable.destroy()
				}

				const formData = new FormData()
				formData.append("id", id)
				formData.append("page", this.bidLogPage)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/bids/logs",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.bidLogs = response.data.data
						this.bidLogPages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loadingBidLogs = false

					setTimeout(function () {
						self.bidLogsDataTable = jQuery("#bid-logs-data").DataTable({
							ordering: false
						})
					}, 500)
				}
			},

			deleteBid: function (id) {
				const self = this

				swal.fire({
					title: 'Delete Bid',
					text: "Are you sure you want to delete this bid ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/bids/delete",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Bid Deleted", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id == id) {
						    			self.data[a].isTrashed = true
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							if (self.dataTable != null) {
				            	self.dataTable.destroy()
				            }

							setTimeout(function () {
					    		self.initialize()
					    	}, 500)
						}
					}
				})
			},

			auctionCancelled: function(id) {
				const self = this

				swal.fire({
					title: 'Auction Cancelled',
					text: "Are you sure you want to mark this car's auction as cancelled ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, auction cancelled!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/auctionCars/auctionCancelled",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Auction Cancelled", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].auctionCar.CAR_ID == id) {
						    			self.data[a].auctionCar.STATUS = "Auction Cancelled"
						    			self.data[a].auctionCar.FINISH = ""
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						}
					}
				})
			},

			doUpdateBidPrice: function (id, amount) {
				const self = this

				return new Promise(async function (callBack) {
					const formData = new FormData()
	        		formData.append("id", id)
	        		formData.append("amount", amount)

	        		try {
	        			const response = await axios.post(
					        self.$apiURL + "/admin/bids/updatePrice",
					        formData,
					        {
					            headers: self.$headers
					        }
					    )
					 
					    if (response.data.status == "success") {
					    	swal.fire("Update Price", response.data.message, "success")

					    	for (let a = 0; a < self.data.length; a++) {
					    		if (self.data[a].id == id) {
					    			self.data[a].bidAmount = amount + ""
					    		}
					    	}
					    } else {
					        swal.fire("Error", response.data.message, "error")
					    }
	        		} catch (error) {
						if (error?.response?.status == 401) {
							swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						}
					} finally {
						callBack()
					}
				})
			},

			updateBidPrice: function (id) {
				const self = this

				swal.fire({
					title: "Set Bid Price",
					input: 'number',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Set Price',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					},

					preConfirm: function (value) {
						return self.doUpdateBidPrice(id, value)
					}
				}).then(async function (result) {
					if (result.isConfirmed) {
						// 
					}
				})
			},

			doAwardBid: function (id, amount) {
				const self = this

				return new Promise(async function (callBack) {
					const formData = new FormData()
	        		formData.append("id", id)
	        		formData.append("amount", amount)

	        		try {
	        			const response = await axios.post(
					        self.$apiURL + "/admin/bids/award",
					        formData,
					        {
					            headers: self.$headers
					        }
					    )
					 
					    if (response.data.status == "success") {
					    	swal.fire("Bid Awarded", response.data.message, "success")

					    	for (let a = 0; a < self.data.length; a++) {
					    		if (self.data[a].id == id) {
					    			self.data[a].isAwarded = 1
					    			self.data[a].auctionCar.STATUS = "Awarded"
					    			self.data[a].auctionCar.FINISH = amount + ""
					    		}
					    	}
					    } else {
					        swal.fire("Error", response.data.message, "error")
					    }
	        		} catch (error) {
						if (error?.response?.status == 401) {
							swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						}
					} finally {
						callBack()
					}
				})
			},

			unAwardBid: function (id) {
				const self = this

				swal.fire({
					title: 'Un-award bid',
					text: "Are you sure you want to un-award this bid ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, un-award it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		try {
		        			const response = await axios.post(
						        self.$apiURL + "/admin/bids/unAward",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Bid un-awarded", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id == id) {
						    			self.data[a].isAwarded = 0
						    			self.data[a].auctionCar.STATUS = ""
						    			self.data[a].auctionCar.FINISH = ""
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
		        		} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						}
					}
				})
			},

			awardWishlist(id) {
				const self = this

				swal.fire({
					title: "Set Awarded Price",
					input: 'number',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Award Wishlist',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					},

					preConfirm: function (value) {
						return new Promise(async function (callBack) {
							const formData = new FormData()
			        		formData.append("id", id)
			        		formData.append("amount", value)

			        		try {
			        			const response = await axios.post(
							        self.$apiURL + "/admin/bookmarks/award",
							        formData,
							        {
							            headers: self.$headers
							        }
							    )
							 
							    if (response.data.status == "success") {
							    	swal.fire("Wishlist Awarded", response.data.message, "success")

							    	for (let a = 0; a < self.data.length; a++) {
							    		if (self.data[a].id == id) {
							    			self.data[a].isAwarded = 1
							    			self.data[a].auctionCar.STATUS = "Awarded"
							    			self.data[a].auctionCar.FINISH = value + ""
							    		}
							    	}
							    } else {
							        swal.fire("Error", response.data.message, "error")
							    }
			        		} catch (error) {
								if (error?.response?.status == 401) {
									swal.fire("Error", error.response.data.message || "Unauthorized", "error")
								}
							} finally {
								callBack()
							}
						})
					}
				}).then(async function (result) {
					if (result.isConfirmed) {
						// console.log([id, result])
					}
				})
			},

			awardBid: function (id) {
				const self = this

				swal.fire({
					title: "Set Awarded Price",
					input: 'number',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Award Bid',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					},

					preConfirm: function (value) {
						return self.doAwardBid(id, value)
					}
				}).then(async function (result) {
					if (result.isConfirmed) {
						// 
					}
				})
			},

			doSetStatusAndFinalPrice: function (id, status, amount) {
				const self = this

				return new Promise(async function (callBack) {
					const formData = new FormData()
	        		formData.append("id", id)
	        		formData.append("status", status)
	        		formData.append("finish", amount)

					try {
						const response = await axios.post(
					        self.$apiURL + "/admin/auctionCars/updateStatus",
					        formData,
					        {
					            headers: self.$headers
					        }
					    )
					 
					    if (response.data.status == "success") {
					    	swal.fire("Update Status", response.data.message, "success")

					    	for (let a = 0; a < self.data.length; a++) {
					    		if (self.data[a].auctionCar.CAR_ID == id) {
					    			self.data[a].auctionCar.STATUS = status
					    			self.data[a].auctionCar.FINISH = result.value + ""
					    		}
					    	}
					    } else {
					        swal.fire("Error", response.data.message, "error")
					    }
					} catch (error) {
						if (error?.response?.status == 401) {
							swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						}
					} finally {
						callBack()
					}
				})
			},

			setStatusAndFinalPrice: function (status, id) {
				const self = this

				swal.fire({
					title: 'Set ' + status + " Final Price",
					input: 'number',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Set Price',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					},

					preConfirm: function (value) {
						return self.doSetStatusAndFinalPrice(id, status, value)
					}
				})
			},

			addBidCommentModal: function (bidId) {
				const self = this

				swal.fire({
					title: 'Enter Bid Comments',
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Add Comments',
					showLoaderOnConfirm: true,
					allowOutsideClick: function () {
						!swal.isLoading()
					}
				}).then(async function (result) {
					if (result.isConfirmed) {

						const formData = new FormData()
		        		formData.append("bidId", bidId)
		        		formData.append("comment", result.value)

		        		try {
		        			const response = await axios.post(
						        self.$apiURL + "/admin/bids/addComment",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Bid Comment", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id == bidId) {
						    			self.data[a].adminComments = result.value
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
		        		} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						}
					}
				})
			},

        	getData: async function () {
        		const self = this
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
        			this.dataTable.destroy()
        		}

        		const formData = new FormData()
        		formData.append("date", this.date)
        		formData.append("timezone", timezone)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/bids",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			async markNotificationsAsRead () {
        		const formData = new FormData()
        		formData.append("type", "bidUpdated")

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/markNotificationsAsRead",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    if (response.data.status == "success") {
				    	store.commit("setBidUpdated", 0)
				    } else {
				        // swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					// if (error?.response?.status == 401) {
					// 	swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					// }
				}
			},

			initialize() {
				const self = this

	            jQuery(".date").datetimepicker({
	                "format": "Y-m-d",
	                "timepicker": false,
	                "onSelectDate": function() {
	                	const d = jQuery('.date').datetimepicker('getValue')
	                	self.getDate(null, new Date(d))
	                	self.addOrUpdateURLParam("date", self.date)
	                    self.getData()
	                },
	                "scrollMonth": false
	            })

	            if (this.$isMobile()) {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
	            } else {
	            	// this.dataTable = jQuery("#data-table").DataTable({
		            // 	ordering: false
		            // })
	            }
	        },

	        getDate(date, dateObj) {
	        	if (!date) {
					let month = (dateObj.getMonth() + 1)
					if (month < 10) {
						month = "0" + month
					}

					let dateVal = dateObj.getDate()
					if (dateVal < 10) {
						dateVal = "0" + dateVal
					}

					date = dateObj.getFullYear() + "-" + month + "-" + dateVal
				}

				this.date = date
	        }
        },

		mounted() {
			this.getDate(this.$route.query.date, new Date())
        	this.getData()
        	this.markNotificationsAsRead()
        	document.title = "Bids"
        }
	}
</script>

<style scoped>
	#bids-data img {
		height: 100px;
		display: inline-block;
	}
</style>
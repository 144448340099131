<template>
    <div class="loading" style="display: none;">Loading&#8230;</div>
    <header class="topbar" data-navbarbg="skin5" v-if="user != null">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
            <div class="navbar-header" data-logobg="skin6">
                <!-- ============================================================== -->
                <!-- Logo -->
                <!-- ============================================================== -->
                <a class="navbar-brand" v-bind:href="$homeURL" target="_blank">
                    <!-- Logo icon -->
                    <b class="logo-icon">
                        <!-- Dark Logo icon -->
                        <img src="../../assets/images/logo.png" alt="homepage" style="width: 200px;" />
                    </b>
                    <!--End Logo icon -->
                </a>
                <!-- ============================================================== -->
                <!-- End Logo -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- toggle and nav items -->
                <!-- ============================================================== -->
                <a class="nav-toggler waves-effect waves-light text-dark d-block d-md-none"
                    href="javascript:void(0)"><i class="ti-menu ti-close"></i></a>
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
               
                <!-- ============================================================== -->
                <!-- Right side toggle and nav items -->
                <!-- ============================================================== -->
                <ul class="navbar-nav ms-auto d-flex align-items-center">

                    <!-- ============================================================== -->
                    <!-- Search -->
                    <!-- ============================================================== -->
                    <li class=" in">
                        <form role="search" class="app-search d-none d-md-block me-3">
                            <input type="text" placeholder="Search..." class="form-control mt-0">
                            <a href="" class="active">
                                <i class="fa fa-search"></i>
                            </a>
                        </form>
                    </li>
                    <!-- ============================================================== -->
                    <!-- User profile and search -->
                    <!-- ============================================================== -->
                    <li>
                        <a class="profile-pic" href="javascript:void(0)">
                            <span class="text-white font-medium" v-text="user.email"></span>
                        </a>

                        <a href="javascript:void(0)" v-on:click="doLogout" v-text="loggingOut ? 'Loading...' : 'Logout'"></a>
                    </li>
                    <!-- ============================================================== -->
                    <!-- User profile and search -->
                    <!-- ============================================================== -->
                </ul>
            </div>
        </nav>
    </header>

    <aside class="left-sidebar" data-sidebarbg="skin6" v-if="user != null">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar">
            <!-- Sidebar navigation-->
            <nav class="sidebar-nav">
                <ul id="sidebarnav">
                    <!-- User Profile-->
                    <li class="sidebar-item pt-2">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/') ? ' active' : '')" to="/"
                            aria-expanded="false">
                            <i class="fa fa-chart-bar" aria-hidden="true"></i>
                            <span class="hide-menu">Dashboard</span>
                            <span class="badge" v-if="startingPrice > 0" v-text="startingPrice"></span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('banner')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/banners') ? ' active' : '')" to="/banners"
                            aria-expanded="false">
                            <i class="fa fa-image" aria-hidden="true"></i>
                            <span class="hide-menu">Banners</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('liveVideo')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/liveVideo') ? ' active' : '')" to="/liveVideo"
                            aria-expanded="false">
                            <i class="fa fa-video" aria-hidden="true"></i>
                            <span class="hide-menu">Live Video</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('panoramic')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/panoramic') ? ' active' : '')" to="/panoramic"
                            aria-expanded="false">
                            <i class="fa fa-car" aria-hidden="true"></i>
                            <span class="hide-menu">360&deg;</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'superAdmin' || user.role == 'developer'">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/contact-us') ? ' active' : '')" to="/contact-us"
                            aria-expanded="false">
                            <i class="fa fa-car" aria-hidden="true"></i>
                            <span class="hide-menu">Contact us</span>
                            <span class="badge badge-success" v-if="unreadContactUs > 0" v-text="unreadContactUs"
                                style="background-color: #30323e;
                                    position: absolute;
                                    right: 10px;"></span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('viewUsers')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/users') ? ' active' : '')" to="/users"
                            aria-expanded="false">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            <span class="hide-menu">Users</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.hasChatAccess">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/chats') ? ' active' : '')" to="/chats"
                            aria-expanded="false">
                            <i class="fa fa-comments" aria-hidden="true"></i>
                            <span class="hide-menu">Chats</span>
                            <span class="badge" v-if="unreadMessages > 0" v-text="unreadMessages"></span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.canSteer">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/steering') ? ' active' : '')" to="/steering"
                            aria-expanded="false">
                            <i class="fa fa-comment" aria-hidden="true"></i>
                            <span class="hide-menu">Steering</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('countries') || hasPermission('countrySlides') || hasPermission('countryFAQs')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/countries') ? ' active' : '')" to="/countries"
                            aria-expanded="false">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                            <span class="hide-menu">Countries</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('viewBids')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/bids') ? ' active' : '')" to="/bids"
                            aria-expanded="false">
                            <i class="fa fa-gavel" aria-hidden="true"></i>
                            <span class="hide-menu">Bids</span>
                            <span class="badge" v-if="bidUpdated > 0" v-text="bidUpdated"></span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('viewImageRequests')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/requested/images') ? ' active' : '')" to="/requested/images"
                            aria-expanded="false">
                            <i class="fa fa-image" aria-hidden="true"></i>
                            <span class="hide-menu">Requested Images</span>
                            <span class="badge" v-if="images > 0" v-text="images"></span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('viewAuctionSheets')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/requested/auctionSheets') ? ' active' : '')" to="/requested/auctionSheets"
                            aria-expanded="false">
                            <i class="fa fa-language" aria-hidden="true"></i>
                            <span class="hide-menu">Auction Sheet Translation</span>
                            <span class="badge" v-if="auctionSheet > 0" v-text="auctionSheet"></span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('viewAwardedCars')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/awardedCars') ? ' active' : '')" to="/awardedCars"
                            aria-expanded="false">
                            <i class="fa fa-trophy" aria-hidden="true"></i>
                            <span class="hide-menu">Awarded Cars</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('viewTransactions')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/transactions') ? ' active' : '')" to="/transactions"
                            aria-expanded="false">
                            <i class="fa fa-dollar-sign" aria-hidden="true"></i>
                            <span class="hide-menu">Transactions</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('addTransaction')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/transactions/add') ? ' active' : '')" to="/transactions/add"
                            aria-expanded="false">
                            <i class="far fa-money-bill-alt" aria-hidden="true"></i>
                            <span class="hide-menu">Add Transaction</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'superAdmin' || user.role == 'developer'">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/userTracking') ? ' active' : '')" to="/userTracking">
                            <i class="fa fa-sitemap"></i>
                            <span class="hide-menu">User Tracking</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'">
                        <router-link to="/auctionCars" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/auctionCars') ? ' active' : '')">
                            <i class="fa fa-car"></i>
                            <span class="hide-menu">Auction Cars</span>
                        </router-link>
                    </li>

                    <!--<li class="sidebar-item" v-if="user.role == 'superAdmin' || user.role == 'developer'">
                        <router-link to="/userCars" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('') ? ' active' : '')">
                            <i class="fa fa-car"></i>
                            <span class="hide-menu">User Cars</span>
                        </router-link>
                    </li>-->

                    <li class="sidebar-item" v-if="user.role == 'superAdmin' || user.role == 'developer'"
                    >
                        <router-link to="/mails" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/mails') ? ' active' : '')">
                            <i class="fa fa-envelope"></i>
                            <span class="hide-menu">Mails</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('parts')">
                        <router-link to="/parts" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/parts') ? ' active' : '')">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 13px;
                                margin-left: 10px;
                                margin-right: 10px;"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M80 96c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32l96 0c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32h16c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64l16 0zm304 96c0-8.8-7.2-16-16-16s-16 7.2-16 16v32H320c-8.8 0-16 7.2-16 16s7.2 16 16 16h32v32c0 8.8 7.2 16 16 16s16-7.2 16-16V256h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H384V192zM80 240c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16z"/></svg>
                            <span class="hide-menu">Parts</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'"
                    >
                        <router-link to="/pages" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/pages') ? ' active' : '')">
                            <i class="fa fa-file-alt"></i>
                            <span class="hide-menu">Pages</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('orders')">
                        <router-link to="/orders" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/orders') ? ' active' : '')">
                            <i class="fa fa-truck"></i>
                            <span class="hide-menu">Orders</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('payments')">
                        <router-link to="/payments" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/payments') ? ' active' : '')">
                            <i class="fa fa-dollar-sign"></i>
                            <span class="hide-menu">Payments</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="hasPermission('invoices')">
                        <router-link v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/invoices') ? ' active' : '')" to="/invoices">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="width: 13px;
                                margin-left: 10px;
                                margin-right: 10px;"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M358.4 3.2L320 48 265.6 3.2a15.9 15.9 0 0 0-19.2 0L192 48 137.6 3.2a15.9 15.9 0 0 0-19.2 0L64 48 25.6 3.2C15-4.7 0 2.8 0 16v480c0 13.2 15 20.7 25.6 12.8L64 464l54.4 44.8a15.9 15.9 0 0 0 19.2 0L192 464l54.4 44.8a15.9 15.9 0 0 0 19.2 0L320 464l38.4 44.8c10.5 7.9 25.6.4 25.6-12.8V16c0-13.2-15-20.7-25.6-12.8zM320 360c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v16z"/>
                            </svg>
                            <span class="hide-menu">Invoices</span>
                        </router-link>
                    </li>
                    
                    <li class="sidebar-item" v-if="hasPermission('investorPanel')">
                        <router-link to="/investorPanel" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/investorPanel') ? ' active' : '')">
                            <i class="fa fa-user-plus"></i>
                            <span class="hide-menu">Investor Panel</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'">
                        <router-link to="/payees" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/payees') ? ' active' : '')">
                            <i class="fa fa-university"></i>
                            <span class="hide-menu">Payees</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'">
                        <router-link to="/sms" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/sms') ? ' active' : '')">
                            <i class="fa fa-comments"></i>
                            <span class="hide-menu">SMS</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'">
                        <router-link to="/testimonials" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/testimonials') ? ' active' : '')">
                            <i class="fa fa-star"></i>
                            <span class="hide-menu">Testimonials</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'">
                        <router-link to="/faqs" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/faqs') ? ' active' : '')">
                            <i class="fa fa-question-circle"></i>
                            <span class="hide-menu">FAQs</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'developer'">
                        <router-link to="/configurations" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/configurations') ? ' active' : '')">
                            <i class="fa fa-cog"></i>
                            <span class="hide-menu">Configurations</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item" v-if="user.role == 'superAdmin' || user.role == 'developer'">
                        <router-link to="/irs" v-bind:class="'sidebar-link waves-effect waves-dark' + (pathname.includes('/irs') ? ' active' : '')">
                            <i class="fa fa-users"></i>
                            <span class="hide-menu">IRS</span>
                        </router-link>
                    </li>

                    <li class="sidebar-item">
                        <a v-on:click.prevent="doLogout" href="javascript:void(0)" class="sidebar-link waves-effect waves-dark">
                            <i class="fa fa-power-off"></i>
                            <span class="hide-menu">Logout</span>
                        </a>
                    </li>
                </ul>

            </nav>
            <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
    </aside>
</template>

<script>

    import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"
    import jQuery from "jquery"
    import { io } from "socket.io-client"

    // import "../../assets/plugins/bower_components/chartist/dist/chartist.min.css"
    // import "../../assets/plugins/bower_components/chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css"
    import "../../assets/css/style.min.css"

    import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

    // import "../../assets/toastify/toastify.css"
    // import "../../assets/toastify/toastify.js"

    import Toastify from "toastify-js"

	export default {
		name: "AppHeader",

        computed: {
            user() {
                return store.getters.getUser
            },

            messages() {
                return store.getters.getMessages
            },

            selectedUserEmail() {
                return store.getters.getSelectedUserEmail
            },

            socketIO() {
                return store.getters.getSocketIO
            },

            unreadContactUs() {
                return store.getters.getUnreadContactUs
            },

            unreadMessages() {
                return store.getters.getUnreadMessages
            },

            bidUpdated() {
                return store.getters.getBidUpdated
            },

            startingPrice() {
                return store.getters.getStartingPrice
            },

            images() {
                return store.getters.getImages
            },

            auctionSheet() {
                return store.getters.getAuctionSheet
            }
        },

        data() {
            return {
                loggingOut: false,
                pathname: window.location.pathname || ""
            }
        },

        methods: {

            doLogout: async function () {
                const self = this

                if (localStorage.getItem(this.$accessToken) == null) {
                    this.$headers.Authorization = "Bearer "
                    store.commit("setUser", null)
                    this.$router.push("/login")

                    return
                }

                this.loggingOut = true

                const response = await axios.post(
                    this.$apiURL + "/logout",
                    null,
                    {
                        headers: this.$headers
                    }
                )

                this.loggingOut = false
             
                if (response.data.status == "success") {
                    // remove access token from local storage
                    localStorage.removeItem(this.$accessToken)

                    this.$headers.Authorization = "Bearer "
                    store.commit("setUser", null)
             
                    // swal.fire("Logout", response.data.message, "success")
                    //     .then(function () {
                            self.$router.push("/login")
                        // })
                } else {
                    swal.fire("Error", response.data.message, "error")
                }
            },

            async getStatistics () {
                try {
                    const response = await axios.post(
                        this.$apiURL + "/admin/getStatistics",
                        null,
                        {
                            headers: this.$headers
                        }
                    )

                    store.commit("setUnreadContactUs", response.data.unread_contact_us)
                } catch (error) {
                    // 
                }
            },

            async getUser () {
                const self = this
                if (localStorage.getItem(this.$accessToken) == null) {
                    this.$router.push("/login")
                    return
                }

                store.commit("setLoading", true)

                try {
                    const response = await axios.post(
                        this.$apiURL + "/admin/getUser",
                        null,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        store.commit("setUser", response.data.user)
                        // store.commit("setSocketIO", io(this.$nodeURL))
                        store.commit("setUnreadMessages", response.data.unreadMessages)
                        store.commit("setBidUpdated", response.data.bidUpdated)
                        store.commit("setStartingPrice", response.data.startingPrice)
                        store.commit("setImages", response.data.images)
                        store.commit("setAuctionSheet", response.data.auctionSheet)
                        
                        this.socketIO.emit("connected", response.data.user.email)
                        this.socketIO.on("newMessage", function (message) {
                            if (message.userEmail == self.selectedUserEmail) {
                                store.commit("pushMessage", message)
                            }

                            store.commit("setUnreadMessages", ++self.unreadMessages)

                            const audio = new Audio(require("@/assets/mp3/new-message.mp3"))
                            audio.setAttribute("preload", "auto")
                            audio.play()

                            const toastify = Toastify({
                                text: "New message",
                                duration: 3000,
                                // destination: "https://github.com/apvarun/toastify-js",
                                newWindow: false,
                                // close: true,
                                gravity: "bottom", // `top` or `bottom`
                                position: "center", // `left`, `center` or `right`
                                stopOnFocus: true, // Prevents dismissing of toast on hover
                                style: {
                                    background: "#96c93d",
                                    bottom: "20px !important",
                                    position: "fixed",
                                    zIndex: "1000",
                                    left: "6%",
                                    padding: "10px 20px",
                                    color: "white",
                                    borderRadius: "5px"
                                },
                                onClick: function() {} // Callback after click
                            }).showToast()
                        })
                    } else {
                        this.$router.push("/login")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        this.$router.push("/login")
                    }
                } finally {
                    store.commit("setLoading", false)
                    
                    setTimeout(function () {
                        global.attachEventListeners()
                    }, 500)
                }
            },
        },

        mounted: function () {
            this.getUser()
            this.getStatistics()
        },

        watch: {
            $route(to, from) {
                if (global.isNavtoggler) {
                    jQuery("#main-wrapper").toggleClass("show-sidebar")
                    jQuery(".nav-toggler i").toggleClass("ti-menu")
                    global.isNavtoggler = false
                }

                store.commit("setMessages", [])
                this.pathname = to.path || ""
            }
        }
	}
</script>

<style>
    .table-bordered,
    .table-bordered td,
    .table-bordered th {
        border-collapse: collapse;
        border: 1px solid lightgray;
    }
    .toastify {
        transform: translateX(-50%) !important;
    }
</style>

<style scoped>
    .sidebar-item .badge {
        background-color: lightseagreen;
        position: absolute;
        right: 10px;
    }
    /* Absolute Center Spinner */
    .loading {
      position: fixed;
      z-index: 999;
      height: 2em;
      width: 2em;
      overflow: show;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    /* Transparent Overlay */
    .loading:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
        background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

      background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
    }

    /* :not(:required) hides these rules from IE9 and below */
    .loading:not(:required) {
      /* hide "loading..." text */
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0;
    }

    .loading:not(:required):after {
      content: '';
      display: block;
      font-size: 10px;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
      -webkit-animation: spinner 150ms infinite linear;
      -moz-animation: spinner 150ms infinite linear;
      -ms-animation: spinner 150ms infinite linear;
      -o-animation: spinner 150ms infinite linear;
      animation: spinner 150ms infinite linear;
      border-radius: 0.5em;
      -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
    }

    /* Animation */

    @-webkit-keyframes spinner {
      0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-moz-keyframes spinner {
      0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-o-keyframes spinner {
      0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes spinner {
      0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
</style>
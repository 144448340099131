<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Contact Us</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Contact Us</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

    	<div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Contact Us (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="" id="data-table">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>Name</th>
                                	<th>Email</th>
                                	<th>Subject</th>
                                	<th>Message</th>
                                	<th>Status</th>
                                	<th>Email Content</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data" v-bind:key="'contact-us-' + d.id">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">Name</th>
													<td v-text="d.name"></td>
												</tr>

												<tr>
													<th class="border-top-0">Email</th>
													<td v-text="d.email"></td>
												</tr>

												<tr>
													<th class="border-top-0">Subject</th>
													<td v-text="d.subject"></td>
												</tr>

												<tr>
													<th class="border-top-0">Message</th>
													<td v-text="d.message"></td>
												</tr>

												<tr>
													<th class="border-top-0">Email Content</th>
													<td>
	                            						<i class="fa fa-envelope" style="cursor: pointer;" v-on:click="viewContent(d.id)"></i>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
	                            		<td v-text="d.name"></td>
	                            		<td v-text="d.email"></td>
	                            		<td v-text="d.subject"></td>
	                            		<td v-html="d.message"></td>
	                            		<td>
	                            			<span class="text-success" v-if="d.is_read">Read</span>
	                            			<span class="text-danger" v-else>Un-read</span>
	                            		</td>
	                            		<td>
	                            			<i class="fa fa-envelope" style="cursor: pointer;" v-on:click="viewContent(d.id)"></i>
	                            		</td>                            		
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../vuex/store"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "ContactUs",

		computed: {
			unreadContactUs() {
                return store.getters.getUnreadContactUs
            }
		},

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				searchTimer: null
			}
		},

		methods: {

			onKeyPressSearch() {
				const self = this
				clearTimeout(this.searchTimer)

				this.searchTimer = setTimeout(function () {
					self.pageNumber = 1
					self.addOrUpdateURLParam("page", 1)

					self.getData()
				}, 1000)
			},

			viewContent (id) {
				/*for (let a = 0; a < this.data.length; a++) {
					if (this.data[a].id == id) {
						// document.querySelector("#emailContent .content").innerHTML = this.data[a].email_content
						store.commit("setEmailContent", this.data[a].email_content)
						break
					}
				}

				$("#emailContent").modal("show")*/

				window.open(this.$mainURL + "/read-contact-us-mail/" + id, "_blank")
			},

			paginate (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

			async getData () {
        		const self = this
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/fetchContactUs",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    this.data = response.data.data
				    this.pages = response.data.pages

					store.commit("setUnreadContactUs", this.unreadContactUs - this.data.length)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#data-table").DataTable({
					// 	ordering: false
					// })
				}
	        }
		},

		mounted() {
        	this.getData()
        	document.title = "Contact Us"
        }
	}
</script>

<style>
	#data-table {
		width: 100%;
	}
	#data-table,
	#data-table th,
	#data-table td {
		border: 1px solid lightgray;
		border-collapse: collapse;
	}

	#data-table th,
	#data-table td {
		padding: 15px;
	}
</style>